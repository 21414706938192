import React, { useState } from 'react';
import Typography from "@mui/material/Typography";
const OtherAgents = ({ item }) => {
    const [showAllAgents, setShowAllAgents] = useState(false);

    const handleShowMore = (e) => {
        e.preventDefault();
        setShowAllAgents(true);
    };

    return (
        <Typography className="line-clamp-1 text-16 text-pi-black">
            {item.agents.length > 0 ? (
                <>
                    {(showAllAgents ? item.agents : item.agents.slice(0, 2)).map((agent, index) => (
                        <React.Fragment key={agent.id || index}>
                            {agent.name}
                            {index < (showAllAgents ? item.agents.length : Math.min(item.agents.length, 2)) - 1 && ', '}
                        </React.Fragment>
                    ))}
                    {!showAllAgents && item.agents.length > 2 && (
                        <> and <a href="#" onClick={handleShowMore} className="font-medium">{item.agents.length - 2} others</a></>
                    )}
                    {' '}friend{item.agents.length > 1 ? 's have' : ' has'} joined this mission.{' '}
                    {/* <a className="font-bold !no-underline !text-pi-blue-1">Join Now</a> */}
                </>
            ) : (
                "No other agents' friends have joined this mission yet. "
            )}
        </Typography>
    );
};

export default OtherAgents;