import React, { useState, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useDispatch, useSelector } from "react-redux";
import { savePublicUpload, getAllPublicUploads } from "app/services/store/PublicUploadsSlice";
import { ImageGrid } from "react-fb-image-video-grid"
import ReactImageVideoLightbox from "react-image-video-lightbox";
import ReactPlayer from "react-player";
import { Modal, Typography } from "@mui/material"; import { styled } from "@mui/material/styles";
import LazyLoad from 'react-lazy-load';
import themesConfig from "app/fuse-configs/themesConfig";

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    '& .MuiBox-root': {
        height: '100%',

    },
    '& .image-container': {
        borderRadius: '8px',
        backgroundColor: '#dfdfdf',
    },

}));

const PlayerRoot = styled('div')(({ theme }) => ({
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.4)",
    "& .video-wrapper": {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .play-button-wrapper": {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "auto",
        pointerEvents: "none",
        "& #circle-play-b": {
            cursor: "pointer",
            pointerEvents: "auto",

            "& svg": {
                width: "60px",
                height: "60px",
                fill: "#fff",
                stroke: "#fff",
                cursor: "pointer",
                backgroundColor: "rgba(black, 0.2)",
                borderRadius: "50%",
                opacity: "0.65",
            }
        }
    },
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'rgba(0, 0, 0, 0.5)',
    boxShadow: 0,
    p: 4,
    '& div:first-of-type': {
        backgroundColor: 'transparent !important',
        '& img': {
            zIndex: -1,
            maxWidth: "800px !important",
        },
        '& div': {
            bgcolor: 'rgba(0, 0, 0, 0.15)',
            borderRadius: "999px",
            margin: "6px"
        },
        '& div:first-of-type': {
            bgcolor: 'rgba(0, 0, 0, 0.15)  !important',
            borderRadius: "0px !important",
            margin: "6px"
        }
    }
};
class PlayButtonSvg extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
            </svg>)
    }
}
const PhotoGridView = ({ fileId, single = false, isDisabledModal = false, moduleType = '' }) => {
    const dispatch = useDispatch();

    const [open, setopen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openlightbox = (index) => {
        setCurrentIndex(index);
        setopen(true);
    };

    const [attachmentData, setAttachmentData] = useState([]);

    const extractEmbedUrl = (link) => {
        // Regular expression to match YouTube video URLs
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/i;
        const match = link.match(regex);

        if (match) {
            const videoId = match[1];
            return `https://www.youtube.com/embed/${videoId}`;
        } else {
            // Handle invalid YouTube links
            console('Invalid YouTube link');
        }
    };

    const extractThumbnailUrl = (link) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/i;
        const match = link.match(regex);

        if (match) {
            const videoId = match[1];
            return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
        } else {
            // Handle invalid YouTube links
            console('Invalid YouTube link');
            return ""
        }
    };

    useEffect(() => {
        dispatch(getAllPublicUploads({ id: fileId })).then((action) => {
            const data = action.payload;
            if (data) {
                const finalData = data.map((item, index) => ({
                    ...item,
                    switchType: item.fileType.split('/')[0],
                    type: item.fileType.split('/')[0] == 'image' ? 'photo' : (item.fileType.split('/')[0] == 'youtube' ? 'video' : item.fileType.split('/')[0]),
                    url: item.fileType.split('/')[0] == 'youtube' ? extractEmbedUrl(item.srcUrl) : item.srcUrl,
                    ytThumbnailUrl: item.fileType.split('/')[0] == 'youtube' ? extractThumbnailUrl(item.srcUrl) : '',
                    index: index
                }));
                setAttachmentData(finalData)
            }
        })
    }, [dispatch, fileId]);

    const [openModal, setClose] = React.useState(true);
    const handleClose = () => setClose(true);

    const imageStyles = {
        zIndex: -1,
        width: "33px !important"
    };

    if (!attachmentData.length && (attachmentData.length === 0) && moduleType == 'community') {
        return (<></>);
    }
    return (
        <Root>
            <Box className=" cursor-pointer" sx={{ width: "100%", overflowY: 'unset' }}>
                {!attachmentData.length && (attachmentData.length === 0) ? (
                    <div className="image-container">
                        <LazyLoad>
                            <img
                                src={'assets/images/pi-images/no-image.png'}
                                alt={''}
                                loading="lazy"
                                className="blog"
                            />
                        </LazyLoad>
                    </div>
                ) :
                    (
                        attachmentData.length && (attachmentData.length === 1 || single) ? (
                            (() => {
                                switch (attachmentData[0].switchType) {
                                    case "youtube":
                                        return <div className="image-container">
                                            <LazyLoad>
                                                <PlayerRoot class="video-container" id="video-container">
                                                    <img
                                                        src={attachmentData[0].ytThumbnailUrl}
                                                        alt={attachmentData[0].fileName}
                                                        loading="lazy"
                                                        className="blog"
                                                        onClick={() => openlightbox(attachmentData[0].index)}
                                                    />
                                                    <div class="play-button-wrapper">
                                                        <div title="Play video" class="play-gif" id="circle-play-b" onClick={() => openlightbox(attachmentData[0].index)}>
                                                            <PlayButtonSvg />
                                                        </div>
                                                    </div>
                                                </PlayerRoot>
                                            </LazyLoad>
                                        </div>;
                                    case "video":
                                        return <div className="image-container">
                                            <LazyLoad>
                                                <PlayerRoot class="video-container" id="video-container">
                                                    <video autoPlay={false} className="blog" onClick={() => openlightbox(attachmentData[0].index)}>
                                                        <source
                                                            type={attachmentData[0].fileType}
                                                            src={attachmentData[0].srcUrl}
                                                        />
                                                    </video>
                                                    <div class="play-button-wrapper">
                                                        <div title="Play video" class="play-gif" id="circle-play-b" onClick={() => openlightbox(attachmentData[0].index)}>
                                                            <PlayButtonSvg />
                                                        </div>
                                                    </div>
                                                </PlayerRoot>
                                            </LazyLoad>
                                        </div>;
                                    default:
                                        return <div className="image-container">
                                            <LazyLoad>
                                                <img
                                                    src={attachmentData[0].srcUrl}
                                                    alt={attachmentData[0].fileName}
                                                    loading="lazy"
                                                    className="blog"
                                                    onClick={() => openlightbox(attachmentData[0].index)}
                                                />
                                            </LazyLoad>
                                        </div>;
                                }
                            })()
                        ) : (
                            attachmentData.length > 0 && (

                                <ImageGrid>
                                    {attachmentData.map((item) => (
                                        (() => {
                                            switch (item.switchType) {
                                                case "youtube":
                                                    return <div className="image-container">
                                                        <LazyLoad>
                                                            <PlayerRoot class="video-container" id="video-container">
                                                                <img
                                                                    src={item.ytThumbnailUrl}
                                                                    alt={item.fileName}
                                                                    loading="lazy"
                                                                    className="blog"
                                                                    onClick={() => openlightbox(item.index)}
                                                                />
                                                                <div class="play-button-wrapper">
                                                                    <div title="Play video" class="play-gif" id="circle-play-b" onClick={() => openlightbox(item.index)}>
                                                                        <PlayButtonSvg />
                                                                    </div>
                                                                </div>
                                                            </PlayerRoot>
                                                        </LazyLoad>
                                                    </div>;
                                                case "video":
                                                    return <div className="image-container">
                                                        <LazyLoad>
                                                            <PlayerRoot class="video-container" id="video-container">
                                                                <video autoPlay={false} className="blog" onClick={() => openlightbox(item.index)}>
                                                                    <source
                                                                        type={item.fileType}
                                                                        src={item.srcUrl}
                                                                    />
                                                                </video>
                                                                <div class="play-button-wrapper">
                                                                    <div title="Play video" class="play-gif" id="circle-play-b" onClick={() => openlightbox(item.index)}>
                                                                        <PlayButtonSvg />
                                                                    </div>
                                                                </div>
                                                            </PlayerRoot>
                                                        </LazyLoad>
                                                    </div>;
                                                default:
                                                    return <div className="image-container">
                                                        <LazyLoad>
                                                            <img
                                                                src={item.srcUrl}
                                                                alt={item.fileName}
                                                                loading="lazy"
                                                                className="blog"
                                                                onClick={() => openlightbox(item.index)}
                                                            />
                                                        </LazyLoad>
                                                    </div>;
                                            }
                                        })()
                                    ))
                                    }
                                </ImageGrid>
                            )
                        )
                    )
                }

            </Box >


            {open && !isDisabledModal && (
                <Modal
                    disableEnforceFocus
                    disableAutoFocus
                    keepMounted
                    onClose={handleClose}
                    open={openModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="w-full h-full">
                        <ReactImageVideoLightbox
                            className=' !bg-transparent'
                            data={attachmentData}
                            startIndex={currentIndex}
                            showResourceCount={true}
                            onCloseCallback={() => setopen(false)}
                            imageStyle={imageStyles}
                            onNavigationCallback={(currentIndex) =>
                                console.log(`Current index: ${currentIndex}`)
                            }
                        />
                    </Box>
                </Modal>

            )}
        </Root>

    );
};

export default PhotoGridView;