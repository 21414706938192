
import withReducer from 'app/store/withReducer';
import reducer from './store/searchSlice';
import { getSearchList } from "./store/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import { List, ListItemButton, ListSubheader, ListItemText, Card, IconButton, Icon, CardHeader, Avatar, CardMedia, CardContent, CardActions, Stack, Divider, Box, Button } from "@mui/material";

import { useEffect, useState } from "react";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import AttachmentSvs from 'app/services/AttachmentSvs';
import PhotoGridView from 'app/services/PhotoGridView';
import HomeContent from '../home/content/HomeContent';
import { agentById } from '../../agent/store/agentSlice';
import CustomRoot from '../custom-component/CustomRoot';
const token = AgentJwtService.getDecodedAccessToken();


function ManageSearch() {
    localStorage.setItem('active_menu', 'Search');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [origData, setOrigData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const [keyword, setKeyword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true)
        dispatch(agentById(token.id)).then((action) => {
            setIsUpdated((action.payload.firstName ? true : false))
            if (!action.payload.firstName) {
                setTimeout(() => {
                    navigate("/agentprofile/edit");
                });
            } else {
                dispatch(getSearchList({ agentId: token.id, searchKey: params.get("searchKey") })).then((action) => {
                    setOrigData(action.payload)
                    setLoading(false)
                });
            }
        });
    }, [params.get("searchKey")]);

    function stringToColor(string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }

        return color;
    }
    function population_formatter(reach) {
        if (reach > 999999) {
            return parseFloat(reach / 1000000).toFixed(1) + 'M';
        } else if (reach > 999) {
            return parseFloat(reach / 1000).toFixed(1) + 'K';
        } else {
            return reach;
        }
    }

    if (loading) {
        return <FuseLoading />;
    }
    return (
        <CustomRoot>
            <div className='px-[16rem]'>
                {origData && origData.agents.length == 0 && origData.missions.length == 0 ? (
                    <>
                        <Typography className=" text-20 font-bold mt-40">
                            No results found
                        </Typography>
                        <Divider className='mt-10 mb-40' />
                        <HomeContent module={"search"} type={"recent"} initial={4} />
                    </>
                )
                    : (
                        <>
                            <Typography className=" text-20  mt-40 font-bold ">
                                Search results for "{params.get("searchKey")}"
                            </Typography>
                            <Divider className='mt-10 mb-40' />
                            {origData && origData.agents.length > 0 && (
                                <>
                                    {origData.agents.map((item) => (
                                        (
                                            item.name != " " && (
                                                <>

                                                    <Link
                                                        style={{ textDecoration: 'none' }}
                                                        component={Link}
                                                        to={isUpdated ? "/agentprofile?agentId=" + item.userId : "/agentprofile/edit"}
                                                        className="hover:bg-transparent"
                                                    >
                                                        <Card elevation={0} sx={{ display: 'flex', alignItems: 'center' }} className='rounded-none  bg-transparent my-20'>
                                                            <Box
                                                                sx={{ maxWidth: '350px', width: "100%", height: 200 }}

                                                                className=" bg-transparent flex justify-center"
                                                            >
                                                                <CardHeader
                                                                    sx={{ '& .MuiAvatar-root': { width: "100vw", height: "100vw", maxWidth: 120, maxHeight: 120, fontSize: '3.857143rem' } }}
                                                                    avatar={

                                                                        (
                                                                            <AttachmentSvs module={"at-1"} path={item?.profilePicPath} data={item} />
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                            <CardContent className=' py-0 flex-1'>
                                                                <Typography className=' text-18 font-extrabold capitalize line-clamp-2'>{item.name}</Typography>
                                                                <Typography variant="body2" className='mb-3 text-14'>@{item.username}</Typography>
                                                                <Typography variant="body2" className=' text-14 line-clamp-2'>
                                                                    {item.about}
                                                                    {/* palagay ng About your sa Agent na Data "I am {item.name}, A kind and intelligent man, With a passion for life, And a heart of gold. I am a friend to all, And a foe to none. I am always willing to help, And I always put others before myself" */}
                                                                </Typography>
                                                            </CardContent>

                                                        </Card>
                                                    </Link>
                                                    {origData.agents.length == 1 && item.missions.length != 0 && (
                                                        <>
                                                            <Divider />
                                                            <Typography className=" text-20 font-bold mt-16">
                                                                Related missions for "{params.get("searchKey")}"
                                                            </Typography>
                                                            <Divider className='mt-10 mb-40' />

                                                            {
                                                                item.missions.map((n) => (
                                                                    <Card elevation={0} sx={{ display: 'flex', alignItems: 'start' }} className=' rounded-none bg-transparent my-16'>

                                                                        <Box
                                                                            sx={{ maxWidth: '350px', width: "100%", height: 200 }}
                                                                            className="overflow-hidden bg-white"
                                                                        >
                                                                            <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${n.id}`}>
                                                                                <PhotoGridView fileId={n.filesId} single={true} isDisabledModal={true} />
                                                                            </Link>
                                                                        </Box>

                                                                        <CardContent className='flex-1 py-0'>
                                                                            <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${n.id}`}>
                                                                                <Typography className=' text-18 font-extrabold capitalize line-clamp-2'>{n.title}</Typography>
                                                                            </Link>
                                                                            <Typography variant="body2" className='mb-3 text-14'>{population_formatter(n.pinned)} reach · {new Date(n.dateCreated).toLocaleDateString('en-En', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                                                                            <Link
                                                                                style={{ textDecoration: 'none' }
                                                                                }
                                                                                component={Link}
                                                                                to={"/agentprofile?agentId=" + item.userId}
                                                                                className="hover:bg-transparent"
                                                                            >
                                                                                <CardHeader
                                                                                    className='px-0 py-8'
                                                                                    sx={{ '& .MuiAvatar-root': { width: 29, height: 29, fontSize: '1.25rem' } }}
                                                                                    avatar={
                                                                                        (
                                                                                            <AttachmentSvs module={"at-1"} path={item?.profilePicPath} data={item} />
                                                                                        )
                                                                                    }
                                                                                    title={'@' + item.username}
                                                                                />
                                                                            </Link>
                                                                            <Typography variant="body2" className=' text-14 line-clamp-2'>
                                                                                {n.description}
                                                                            </Typography>
                                                                            <Link
                                                                                style={{ textDecoration: 'none' }
                                                                                }
                                                                                component={Link}
                                                                                to={"/public/mission-details/" + n.id}
                                                                                className="hover:bg-transparent"
                                                                            >
                                                                                <Button variant='filled' className=' rounded-md bg-pi-light-grey mt-8'>View mission</Button>
                                                                            </Link>
                                                                        </CardContent>

                                                                    </Card>
                                                                ))
                                                            }
                                                        </>
                                                    )}

                                                </>

                                            )
                                        )

                                    ))}
                                    <div className=' pt-16'></div>
                                </>
                            )}


                            {/* For Blogs Search confirm pa if kasali dito */}
                            {/* < List className=" text-pi-grey fill-pi-grey" component="nav" aria-label="Navigations Menu" sx={{ '& .Mui-selected': { color: "#FBB633 !important" }, '& .Mui-selected svg': { fill: "#FBB633 !important" }, '& .MuiListItemIcon-root svg': { fill: "#7F7F7F" } }} >
                                                <ListSubheader disableSticky component="div" id="nested-list-header" sx={{ fontSize: 22, fontWeight: 'bolder' }} >
                                                    Blogs
                                                </ListSubheader>

                                                {origData.blogs.length > 0 ? (
                                                    origData.blogs.map((item) => (
                                                        <ListItemButton
                                                            component={Link}
                                                            to={token.firstName ? "/agentprofile?agentId=" + item.userId : "/agentprofile/edit"}
                                                            className="my-10"
                                                        >
                                                            <ListItemText
                                                                primaryTypographyProps={{
                                                                    fontSize: 16,
                                                                    fontWeight: 'bold',
                                                                    letterSpacing: 0,
                                                                }}
                                                                primary={item.title} />
                                                        </ListItemButton>
                                                    ))) :
                                                    (
                                                        <Typography className=" text-18 font-bold mt-16">
                                                            There is no result for blogs!!!
                                                        </Typography>
                                                    )
                                                }

                                            </List> */}

                            {origData.agents.length != 1 && origData.missions.length > 0 ? (
                                origData.missions.map((item) => (
                                    <Card elevation={0} sx={{ display: 'flex', alignItems: 'start' }} className=' rounded-none bg-transparent my-16'>

                                        <Box
                                            sx={{ maxWidth: '350px', width: "100%", height: 200 }}

                                            className="overflow-hidden bg-white"
                                        >
                                            <PhotoGridView fileId={item.filesId} />

                                        </Box>

                                        <CardContent className='flex-1 py-0'>
                                            <Typography className=' text-18 font-extrabold capitalize line-clamp-2'>{item.title}</Typography>
                                            <Typography variant="body2" className='mb-3 text-14'>{population_formatter(item.pinned)} reach · {new Date(item.dateCreated).toLocaleDateString('en-En', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                                            <Link
                                                style={{ textDecoration: 'none' }
                                                }
                                                component={Link}
                                                to={"/agentprofile?agentId=" + item.agent.userId}
                                                className="hover:bg-transparent"
                                            >
                                                <CardHeader
                                                    className='px-0 py-8'
                                                    sx={{ '& .MuiAvatar-root': { width: 29, height: 29, fontSize: '1.25rem' } }}
                                                    avatar={
                                                        <AttachmentSvs module={"at-1"} path={item.agent?.profilePicPath} data={item.agent} />
                                                    }
                                                    title={item.agent.username}
                                                />
                                            </Link>
                                            <Typography variant="body2" className=' text-14 line-clamp-2'>
                                                {item.description}
                                            </Typography>
                                            <Link
                                                style={{ textDecoration: 'none' }}
                                                component={Link}
                                                to={"/public/mission-details/" + item.id}
                                                className="hover:bg-transparent"
                                            >
                                                <Button variant='filled' className=' rounded-md bg-pi-light-grey mt-8'>View mission</Button>
                                            </Link>
                                        </CardContent>
                                    </Card>
                                ))) :
                                (
                                    <Typography className=" text-18 font-bold mt-16">

                                    </Typography>
                                )
                            }
                            <div className=' pt-16'></div>
                            <HomeContent module={"search"} type={"recent"} initial={4} />

                        </>
                    )}
            </div>
        </CustomRoot>);
}

export default withReducer('agentsApp', reducer)(ManageSearch);
