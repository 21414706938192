import * as React from "react";
import { useMemo, useState, useEffect, useCallback } from "react";
import { styled } from '@mui/material/styles';
import { Badge, Button, CardHeader, Grid, Paper, Typography, Avatar, Container, Stack, Icon, IconButton } from "@mui/material";
import { generatePath, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMissionById } from "../../store/missionDetailsSlice";
import PhotoGridView from "app/services/PhotoGridView";
import FuseLoading from "@fuse/core/FuseLoading";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import AttachmentSvs from "app/services/AttachmentSvs";
import SeeMoreCollapsible from './component/SeeMoreCollapsible ';
import JoinButtonSvs from "app/services/JoinButtonSvs";
import SharerSvs from "app/services/SharerSvs";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import CarouselView from "./component/CarouselView";
import MissionDetailsSkeletonLoader from "./component/MissionDetailsSkeletonLoader";
import { PinnedIcon } from "app/main/custom-icon/MissionCounterIcons";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LikeButton from "../../../friends-activity/components/LikeButton";


import AgentJwtService from 'app/services/agent/AgentJwtService';
import CommentBox from "app/services/comments/CommentBox";
import MissionDetailJoinersContent from "./MissionDetailJoinersContent";
import Comments from "app/services/comments/Comments";
import MissionDetailMapContent from "./MissionDetailMapContent";
import { getComments } from "app/services/comments/store/commentsSlice";


const token = AgentJwtService.getDecodedAccessToken();
const styles = {
    gradientBackground: {
        paddingBottom: "70px",
        paddingTop: "30px",
        display: 'flex',
        maxWidth: '639px',
        minHeight: "fit-content",
        flexDirection: "column",
        justifyContent: 'start',
        background: 'white',
        backgroundImage: 'url("assets/images/pi-images/global-map.svg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    imageContainer: {
        '& .image-container': {
            paddingBottom: '42%',
            borderRadius: "unset !important",
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_2_1.image-container': {
            paddingBottom: '84%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_3_1.image-container': {
            paddingBottom: '21%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_4_1.image-container': {
            paddingBottom: '28%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },

    },
};

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
}));

function MissionDetailContent(params) {

    const paramss = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const [likers, setLikers] = useState("");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const navigate = useNavigate();

    const { mission_id, activeMission, setActiveMission, markerPosition, setMarkerPosition, isDisabled, activeKingPin, handleItemCenter, scrollToRef, mdData } = params;

    const agentId = paramss.get("agentId") || token.id;

    useEffect(() => {
        dispatch(getMissionById({ id: params.mission_id })).then((action) => {
            const data = action.payload;
            if (!data || data.status == "0") {
                navigate(generatePath("/public/home"));
            }
            setData(data)
            console.log('paramgetMissionByIdsssssssssss', data);
            fetchComments();
            setLoading(false)
        })
    }, [dispatch]);

    function fetchComments() {
        dispatch(getComments({ moduleId: mission_id, moduleType: 'missions' })).then((action) => {
            const filteredComments = action.payload.filter(item => item.userId)
            setComments(filteredComments);
        })
    }

    const fetchLikeData = async () => {
        try {
            const action = await dispatch(getMissionById({ id: params.mission_id }));
            const newData = action.payload;
            if (newData && newData.status !== "0") {
                if (!data?.markers) {
                    setData(prevData => ({
                        ...prevData,
                        markers: [
                            {
                                ...prevData.markers[0],
                                likers: newData.markers[0].likers,
                            }
                        ],
                        views: newData.views,
                        commentsCount: newData.commentsCount,
                        pinned: newData.pinned,
                    }));
                } else {
                    setData(prevData => ({
                        ...prevData,
                        views: newData.views,
                        commentsCount: newData.commentsCount,
                        pinned: newData.pinned,
                    }));
                }
            }
            const getComms = await dispatch(getComments({ moduleId: mission_id, moduleType: 'missions' }));
            const newComms = getComms.payload.filter(item => item.userId)
            if (newComms && newComms.status !== "0") {
                setComments(newComms);
            }

        } catch (error) {
            console.error('Error fetching like data:', error);
        }
    };

    useEffect(() => {
        if (data?.markers && data?.markers.length > 0) {
            setLikers(data.markers[0].likers);
        }
    }, [data?.markers]);


    const reloadActivities = useCallback(() => {
        fetchLikeData();
    }, [params.mission_id, dispatch]);


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                handleScrollToTop();
            }
        };

        console.log('params', params);

        const handleScrollToTop = () => {
            params.activeKingPin();

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (loading) {
        return <MissionDetailsSkeletonLoader />;
    }

    return (
        < >
            <Stack className="w-full" direction={'row'} flexWrap={' wrap-reverse'}>
                <div id={data.id} className=" flex-grow basis-xs relative" style={styles.gradientBackground}>
                    <Container>
                        <Typography className=" text-24 font-800">{data.title}</Typography>
                        <CardHeader
                            className="px-0 py-8"
                            sx={{
                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                "& .MuiAvatar-root": { border: '1px solid #FBB633' },


                            }}
                            avatar={
                                <>

                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                        badgeContent={
                                            <span
                                            >
                                                <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                            </span>
                                        }
                                    >
                                        <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${data.agent?.userId}`}>
                                            <AttachmentSvs module={"at-6"} path={data.agent?.profilePicPath} data={{ name: data.agent?.name }} />
                                        </Link>

                                    </Badge>
                                </>
                            }
                            title={
                                <Typography className=" font-800 text-16">
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${data.agent?.userId}`}>
                                        {data.agent.flName} <span className=" !font-500 text-16 text-pi-grey">created this mission</span>
                                    </Link>
                                </Typography>
                            }
                            subheader={
                                <Typography className=" line-clamp-1 text-16 f9ont-500  text-pi-grey">
                                    <DateTimeDisplay dateTime={data.dateCreated} />
                                </Typography>
                            }
                        />
                        <SeeMoreCollapsible text={data.description} maxLength={225} />


                        {agentId != data.agent.userId ? <JoinButtonSvs agent_id={data.agent.userId} mission_id={params.mission_id} module={"at-4"} /> : null}



                    </Container>
                    <Stack className=" bg-grey-200 bg-opacity-90 absolute bottom-0 w-full px-24 py-6" direction={'row'} justifyContent={"space-between"} alignItems={'center'} sx={{
                        '& .MuiButton-root':
                        {
                            color: '#2EB7D7 !important'

                        }
                    }}>
                        <Stack direction={'row'} gap={1.5} justifyContent={''} className="  items-center"
                            sx={{
                                "& .favorite-border-icon-color": { fill: 'black !important' },
                            }}
                        >
                            <div className="flex items-center">
                                <LikeButton
                                    missionId={params.mission_id}
                                    userId={data.agent?.userId}
                                    flName={data.agent?.flName}
                                    reloadActivities={reloadActivities}
                                    likers={likers}
                                    link={'/public/mission-details/' + mission_id}
                                />
                            </div>
                            <Typography className="h-fit">{data.views} Views</Typography>
                            <Typography className="h-fit">{data.commentsCount} Comments</Typography>
                            <Typography className="h-fit">{data.pinned} Pinned</Typography>
                        </Stack>
                        <SharerSvs link={`/public/mission-details/${params.mission_id}`} title={data.title} description={data.description} />

                    </Stack>
                </div>
                <Container id={data.id} className="flex-grow basis-xs p-0 max-w-full " sx={{ ...styles.imageContainer }} >
                    {/* <PhotoGridView fileId={data.filesId} /> */}
                    <CarouselView fileId={data.filesId} />
                </Container >
            </Stack >
            <Stack className="w-full" direction={'row'} flexWrap={'wrap-reverse'}>
                <div className=" h-fit flex-grow basis-xs" style={{ alignSelf: "start", maxWidth: "639px" }}>
                    <div className='p-16 pb-0' sx={{ '& .MuiPaper-root': { marginButton: "0px" } }}>
                        <CommentBox comments={comments} setComments={setComments} missionData={data} moduleType="missions" moduleId={mission_id} reloadActivities={reloadActivities} />
                        <MissionDetailJoinersContent mission_id={mission_id} activeMission={activeMission} setActiveMission={setActiveMission} markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} isDisabled={isDisabled} handleItemCenter={handleItemCenter} data={mdData} loading={loading} />
                        <Comments comments={comments} setComments={setComments} missionData={data} moduleType="missions" moduleId={mission_id} reloadActivities={reloadActivities} loading={loading} setLoading={setLoading} />
                    </div>
                </div>
                <div className=" flex-grow basis-xs sticky">
                    <div className="map-sticky">
                        <MissionDetailMapContent mission_id={mission_id} activeMission={activeMission} setActiveMission={setActiveMission} markerPosition={markerPosition} setMarkerPosition={setMarkerPosition} scrollToRef={scrollToRef} />
                    </div>
                </div>
            </Stack>
        </>

    )
}

export default MissionDetailContent;