import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
import AgentJwtService from "app/services/agent/AgentJwtService";
import { showMessage } from "app/store/fuse/messageSlice";
import FormHelperText from '@mui/material/FormHelperText';
import { saveComment, getComments, deletePublicComment, getMissionById } from "./store/commentsSlice";
import { deletePublicAttachment } from "app/services/store/PublicUploadsSlice";

import {
    Card,
    CardContent,
    Stack,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Avatar,
    Badge,
    CardHeader,
    IconButton,
    Container,
    Button,
    Divider,
    Icon,
    Tab,
    TextField,
    InputAdornment,
    Typography,
    Menu,
    MenuItem,
    Tooltip
} from "@mui/material";
import DateTimeDisplay from "../DateTimeDisplay";
import CommentReplies from "./CommentReplies";
import AttachmentUploader from "../AttachmentUploader";
import randomString from "app/services/randomString";
import AttachmentContainer from "../AttachmentContainer";
import ReactPlayer from "react-player";
import CommentAction from "./content/CommentAction";

import AppBar from '@mui/material/AppBar';
import Input from '@mui/material/Input';

import DeleteIcon from '@mui/icons-material/Delete';
import AttachmentSvs from "../AttachmentSvs";
import notification from "../notification";
import { addNotification } from "app/fuse-layouts/shared-components/quickPanel/store/dataSlice";
import moment from "moment";
import CommentsSkeletonLoader from "./components/CommentsSkeletonLoader";
import { Link } from "react-router-dom";
import { CommentIconBadge } from "app/main/custom-icon/MissionCounterIcons";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        fontFamily: "Nunito, sans-serif !important",
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
        backgroundColor: "#F8FAFB",
        border: "1px solid #E9E9E9",
        fontSize: '14px',
        borderRadius: '23px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '14px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '14px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '14px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },

}));
const DisabledPlayer = styled('div')(({ theme }) => ({
    position: "relative",

    "& .disable": {
        position: "relative",
        height: 80,
        width: 80,
    },
    "& .react-player": {
        position: "absolute",
        top: 0,
        left: 0,
    },


}));
function CommentBox(props) {
    const dispatch = useDispatch();
    const token = AgentJwtService.getDecodedAccessToken();
    const isValidToken = token && (Array.isArray(token) ? token.length > 0 : Object.keys(token).length > 0);
    const { comments, setComments, missionData, moduleId, moduleType, reloadActivities } = props;


    // const [comments, setComments] = useState(data);
    const [loading, setLoading] = useState(true);
    const [loadingASection, setLoadingASection] = useState(false);
    const [reply, setReply] = useState("");
    const [attachmentTempId, setAttachmentTempId] = useState(randomString())
    const [attachmentData, setAttachmentData] = useState([])
    const [attachmentEditData, setAttachmentEditData] = useState([])
    const [isEditId, setIsEditId] = useState(null);
    const [mission, setMission] = useState(missionData);

    const handleDeleteAttachment = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentData.filter(item => item.id !== id);
            setAttachmentData(updatedAttachments);
        })
    };

    const handleDeleteAttachmentEdit = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentEditData.filter(item => item.id !== id);
            setAttachmentEditData(updatedAttachments);
        })
    };

    const handleDeleteComment = (id) => {
        setLoadingASection(true);
        dispatch(deletePublicComment({ id: id })).then(() => {
            const updatedComments = comments.filter(item => item.id !== id);
            setComments(updatedComments);
            setLoadingASection(false);
        })
    };

    const setEditComment = (comment) => {
        setValue("editComments", comment)
    };

    const setCommentById = (id, comData) => {
        const updatedArray = comments.map(item => {
            if (item.id === id) {
                return { ...item, comments: comData };
            }
            return item;
        });

        setComments(updatedArray);
    }

    const defaultValues = {
        agentId: token.agentId,
        moduleId: moduleId,
        moduleType: moduleType,
        comments: "",
        editComments: "",
        commentType: "",
    };

    const schema = yup.object().shape({
        comments: yup.string().required("Comment is required."),
        editComments: yup.string().required("Comment is required."),
        reply: yup.string().required("Reply is required"),
    });

    function createNotification(obj) {
        dispatch(addNotification(obj));
    }

    function checkLogin() {
        if (!isValidToken) {
            window.location.href = "/agentlogin?return=" + window.location.pathname;
        }
    }

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        trigger,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });


    async function handleComment() {
        var fData = getValues();
        if (fData.comments == '' && attachmentData.length == 0) {
            return dispatch(
                showMessage({
                    message: "Please write a comment or add attachments",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        } else {
            fData.commentType = 0;
            fData.fileId = attachmentTempId;
            dispatch(saveComment(fData)).then((action) => {
                if (action.payload.status == 'success' && moduleType === "missions" && token.id != mission.agent.userId) {
                    createNotification({
                        uid: token.id,
                        uname: token.data.displayName,
                        upicPath: token.data.profilePicPath,
                        userId: mission.agent.userId,
                        userName: mission.agent.flName,
                        link: 'public/mission-details/' + moduleId,
                        message: notification({
                            uname: token.data.displayName,
                            username: mission.agent.flName
                        }, 'mission', 'comment'),
                        module: 'mission',
                        time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                        status: "Unread"
                    })
                }
                setLoadingASection(true);
                reset(defaultValues);
                setAttachmentTempId(randomString());
                setAttachmentData([]);
                reloadActivities();
            });
        }
    }

    const variant = {
        hidden: { opacity: 0, y: 40 },
        show: { opacity: 1, y: 0 },
    };
    return (
        <div>
            <form name="commentForm" noValidate>
                <>
                    <Card
                        component={motion.div}
                        variants={variant}
                        className="w-full overflow-hidden rounded-md shadow-0 mb-32 border-1 border-[#dfdfdf]"
                    >
                        <Controller
                            name="comments"
                            control={control}
                            render={({ field }) => (

                                <>
                                    <Input
                                        {...field}
                                        className="p-16 pb-0 w-full"
                                        classes={{ root: 'text-14' }}
                                        placeholder="Write a comment.."
                                        multiline
                                        minRows="3"
                                        margin="none"
                                        disableUnderline
                                        onFocus={(e) => {
                                            checkLogin();
                                        }}
                                    />

                                    {/* <FormHelperText className="pl-16" error={!!errors.comments}>{errors?.comments?.message}</FormHelperText> */}
                                    {
                                        attachmentData.length > 0 &&
                                        attachmentData.map((item) => (
                                            <Stack direction={'row'} gap={1.5} flexWrap={'wrap'} className="p-8">
                                                <div
                                                    style={{ width: 80, height: 80 }}
                                                    className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                                >
                                                    <Tooltip title="Delete">
                                                        <DeleteIcon className='text-red-600 opacity-50 hover:opacity-100 cursor-pointer absolute top-0 right-3 z-20'
                                                            onClick={() => { handleDeleteAttachment(item.id) }}
                                                        />
                                                    </Tooltip>
                                                    {
                                                        (() => {
                                                            switch (item.switchType) {
                                                                case "youtube":
                                                                    return <div className="image-container">
                                                                        <DisabledPlayer>
                                                                            <div className=" z-10 disable"></div>
                                                                            <ReactPlayer
                                                                                light={true}
                                                                                playing={false}
                                                                                className="react-player rounded-lg overflow-hidden "
                                                                                url={item.srcUrl}
                                                                                height={80}
                                                                                width={80}
                                                                            />
                                                                        </DisabledPlayer>
                                                                    </div>;
                                                                case "video":
                                                                    return <div className="image-container">
                                                                        <video autoPlay={false} className="blog">
                                                                            <source
                                                                                type={item.fileType}
                                                                                src={item.srcUrl}
                                                                            />
                                                                        </video>
                                                                    </div>;
                                                                default:
                                                                    return <div className="image-container2">
                                                                        <img

                                                                            className="max-w-none w-full h-full object-cover"
                                                                            src={item.srcUrl}
                                                                        />
                                                                    </div>;
                                                            }
                                                        })()
                                                    }
                                                </div>
                                            </Stack>
                                        ))
                                    }
                                </>

                            )}
                        />

                        <AppBar
                            className="card-footer flex flex-row border-t-1"
                            position="static"
                            color="default"
                            elevation={0}
                        >
                            <div className="flex-1 items-center">
                                {isValidToken && (
                                    <AttachmentUploader module="missions" id={attachmentTempId} attachmentData={attachmentData} setAttachmentData={setAttachmentData} />
                                )}
                            </div>

                            <div className="p-8">
                                <Button onClick={handleComment} variant="contained" color="primary" size="small" aria-label="post" className=' rounded-md font-semibold'>
                                    Add comment
                                </Button>
                            </div>
                        </AppBar>
                    </Card>
                </>
            </form >
        </div >
    );
}

export default CommentBox;
