const query = {
    getCounters: `
         query getCounters($agentId: String) {
            getCounters(agentId: $agentId) {
                followers
                reach
                missions
            }
        }
    `,
    agentById: `
        query agentById($agentId: String){
            agentById(agentId: $agentId){
                id
                userId
                firstName
                middleName
                lastName
                email
                username
                gender
                birthdate
                country
                city
                about
                profileUrl
                bannerUrl
            }
        }
    `,
};

export { query };