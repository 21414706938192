import React, { useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { GlobalStyles, Box, Hidden } from "@mui/material";
import { useLocation } from "react-router-dom"; // Import useLocation
import AppContext from "app/AppContext";
import useScrollTop from "./hooks/useScrollTop";
import SidePanelMission from "../sidepanel-mission/SidePanelMission";
import FooterLayout1 from "app/fuse-layouts/layout1/components/FooterLayout1";
import NavbarWrapperLayout1 from "app/fuse-layouts/layout1/components/NavbarWrapperLayout1";

const StyledRightContent = styled("div")(({ theme, open, position }) => ({
    minWidth: "350px",
    width: "350px",
    maxWidth: "350px",
    ...(!open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(position === "right" && { marginRight: "-60px" }),
    }),
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Root = styled("div")(({ config }) => ({
    ...(config.mode === "boxed" && {
        maxWidth: `${config.containerWidth}px`,
        margin: "0 auto",
        boxShadow: "0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)",
    }),
    ...(config.mode === "container" && {
        "& .container": {
            maxWidth: `${config.containerWidth}px`,
            width: "100%",
            margin: "0 auto",
        },
    }),
}));

const CustomRoot = ({ children }) => {
    const theme = useTheme();
    const [scrollTop, scrollProps] = useScrollTop();
    const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
    const { routes } = useContext(AppContext);
    const location = useLocation(); // Get the current URL

    // Check if the current URL is /public/friends-activity
    const isFriendsActivityPage = location.pathname === "/public/friends-activity";

    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    ".hide-ico": {
                        display: "none !important",
                    },
                    ".min-w-ico": {
                        // minWidth: "325px !important",
                        // width: "325px !important"
                    },
                    ".shadow-0-ico": {
                        boxShadow: "none !important",
                    },
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root id="fuse-layout" config={config} className="w-full flex">
                <div className="flex flex-auto min-w-0">
                    {config.navbar.display && config.navbar.position === "left" && <NavbarWrapperLayout1 />}
                    <main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
                        {/* Dynamic Main Content */}
                        {children}
                        {/* End of Dynamic Main Content */}
                        {config.footer.display && <FooterLayout1 />}
                    </main>

                    {/* Conditionally render the right sidebar only on /public/friends-activity */}
                    {isFriendsActivityPage && (
                        <Hidden lgDown>
                            <StyledRightContent
                                className="flex-col flex-auto sticky top-[76px] overflow-hidden h-screen min-h-[calc(100vh-76px)] max-h-[calc(100vh-76px)] shrink-0 z-20 shadow-md shadow-0-ico min-w-ico"
                                open={true}
                                position={'right'}
                            >
                                <Box className="h-full overflow-auto overscroll-auto max-h-[100vh] "
                                    {...scrollProps}
                                    sx={{
                                        background: 'white',
                                        boxShadow:
                                            scrollTop > 0 ? "inset 0 8px 5px -9px rgb(0 0 0 / 0.4) !important" : "none",
                                        transition: "box-shadow 0.8s !important",
                                        scrollBehavior: "smooth",
                                        '&::-webkit-scrollbar': { width: '0' },
                                        '&::-webkit-scrollbar-thumb': { boxShadow: 'inset 0 0 0 20px rgba(0, 0, 0, 0)', },
                                        ':hover&::-webkit-scrollbar-thumb': { boxShadow: 'inset 0 0 0 20px rgba(0, 0, 0, 0.24)' },
                                    }}>
                                    <SidePanelMission />
                                </Box>

                            </StyledRightContent>
                        </Hidden>
                    )}
                </div>
            </Root>
        </>
    );
};

export default React.memo(CustomRoot);
