const mutation = {
    updateAgent: `
        mutation updateAgent($data: JSONString!) {
            updateAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updateAccount: `
        mutation updateAccount($data: JSONString!) {
            updateAccount (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updatePassword: `
        mutation updatePassword($data: JSONString!){
            updatePassword(data: $data) {
                res,
                message,
                status
            }
        }
    `,
    checkAccount: `
        mutation checkAccount($data: JSONString!){
            checkAccount(data: $data) {
                res,
                message,
                status
            }
        }
    `,
    likeAgent: `
        mutation likeAgent($data: JSONString) {
           likeAgent (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    agentById: `
        query agentById($agentId: String) {
            agentById(agentId: $agentId) {
                id
                userId
                city
                email
                displayName
                about
                country
                state
                gender
                name
                flName
                username
                firstName
                middleName
                lastName
                birthdate
                agentName
                dateCreated
                profileUrl
                bannerUrl
                dateFormatted
                images {
                    id
                    userId
                    uploadId
                    fileName
                    fileType
                    fileId
                    module
                    path
                    url
                    imageType
                    dateCreated
                    dateUpdated
                }
                bannerImages {
                    id
                    userId
                    uploadId
                    fileName
                    fileType
                    fileId
                    module
                    path
                    url
                    imageType
                    dateCreated
                    dateUpdated
                }
                agentFollowersCount
                getReach {
                    missions
                    markers
                }
            }
        }
    `,
    agentAccountById: `
        query agentById($agentId: String) {
            agentById(agentId: $agentId) {
                id
                userId
                email
                username
            }
        }
    `,
    getMissionJoined: `
        query getMissionJoined($id: String, $agentId: String, $kingpin: String) {
            getMissionJoined(id: $id, agentId: $agentId, kingpin: $kingpin) {
                missions {
                    id
                    title
                    description
                    dateCreated
                    filesId
                    lat
                    lng
                    kingPin
                    missionId
                    missionViews
                    missionPinned
                    countOnCommentsById
                    timeAgo
                    agent{
                        profilePicPath
                        name
                        flName
                        username
                    }
                    agents{
                        name
                        flName
                        firstName
                        lastName
                        username
                        profilePicPath
                        id}
                    joinedAgents{
                        name
                        flName
                        firstName
                        lastName
                        username
                        profilePicPath
                        id
                    }
                }
            }
        }
    `,
    getMarkersByAgentId: `
        query getMarkersByAgentId($id: String, $type: String){
            getMarkersByAgentId(id: $id, type: $type){
                id
                title
                description
                dateCreated
                filesId
                lat
                lng
                kingPin
                missionId
                missionViews
                missionPinned
                countOnCommentsById
                likers
                agent{
                    profilePicPath
                    name
                    flName
                    username
                    profileUrl
                    userId
                }
                agents{
                    name
                    flName
                    firstName
                    lastName
                    username
                    profilePicPath
                    id
                    userId
                }
                joinedAgents{
                    name
                    flName
                    firstName
                    lastName
                    username
                    profilePicPath
                    id
                }
                timeAgo
            }
        }
    `,
};
export { mutation, query };