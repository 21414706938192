import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, InfoBox, InfoWindow, useJsApiLoader, OverlayView } from "@react-google-maps/api";
import CONFIG from "app/config";
import { Card, TextField, Button, Item, Typography, Container, Dialog, Alert, CardHeader, Avatar } from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Box, GlobalStyles, height } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getMarkersByMissionId } from "../../store/missionDetailsSlice";
import { styled } from "@mui/material/styles";

import AttachmentSvs from "app/services/AttachmentSvs";
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import JoinButtonSvs from "app/services/JoinButtonSvs";
import TextLimit from "app/services/TextLimit";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import PhotoGridView from "app/services/PhotoGridView";
import { ButtonUnstyled } from "@mui/base";

const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 80px)',
    minHeight: '400px'
};

const PopupStyled = styled('div')(() => ({
    filter: "drop-shadow(rgba(149, 157, 165, 0.3) 0px 5px 8px)",
    width: "100%",
    margin: "auto",
    position: "relative",
    "& #box-content": {
        background: "white",
        width: "100%",
        height: "auto",
        position: "relative",
        borderRadius: "8px",
        zIndex: '1',
    },
    "& #box-profile": {
        background: "white",
        width: " 115px",
        height: "115px",
        margin: "auto",
        position: "relative",
        borderRadius: "999px",
        top: "-30px",
        left: "-30px",
        zIndex: "1",
        position: "absolute",
    },
    "& #arrow-down": {
        width: "0",
        height: "0",
        borderLeft: "25px solid transparent",
        borderRight: "25px solid transparent",
        borderTop: "25px solid white",
        position: "absolute",
        left: "40%",
        zIndex: "1",
        bottom: "-18px",
    },


}));


function MissionDetailMapContent(params) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: CONFIG.MAPS
    })

    const dispatch = useDispatch();

    const [map, setMap] = useState(null)
    const { markerPosition, setMarkerPosition } = params;
    const [data, setData] = useState(null);
    const { activeMission, setActiveMission } = params;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getMarkersByMissionId({ id: params.mission_id })).then((action) => {
            if (action.payload.length > 0) {
                const kingPin = action.payload.find(item => item.kingPin === 1);
                setActive(kingPin);
                setData(action.payload)
                setLoading(false)
            }
        })
    }, [dispatch]);

    useEffect(() => {
        animateMapCenter(markerPosition);
    }, [markerPosition]);

    const setActive = (data) => {
        if (data) {
            if (data.kingPin == 1) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            } else {
                params.scrollToRef(data.id)
            }
            setMarkerPosition({ lat: data.lat, lng: data.lng });
            setActiveMission(data)
        }
    }

    const animateMapCenter = (newCenter) => {
        if (map) {
            const start = map.getCenter();
            const startTime = Date.now();
            const duration = 50; // Animation duration in milliseconds

            const animate = () => {
                const now = Date.now();
                const elapsedTime = now - startTime;
                const easing = Math.sin((elapsedTime / duration) * (Math.PI / 2));
                const newLat = start.lat() + easing * (newCenter.lat - start.lat());
                const newLng = start.lng() + easing * (newCenter.lng - start.lng());
                const newCenterPosition = { lat: newLat, lng: newLng };

                map.panTo(newCenterPosition);

                if (elapsedTime < duration) {
                    requestAnimationFrame(animate);
                }
            };
            animate();
        }
    };

    return isLoaded && !loading ? (
        <div>
            {/* <GlobalStyles
                styles={(theme) => ({

                    " .gm-style-iw.gm-style-iw-c": {
                        maxWidth: '320px !important'
                    }
                })}
            /> */}
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={markerPosition}
                onLoad={(map) => setMap(map)}
                zoom={14}
            >
                { /* Child components, such as markers, info windows, etc. */}
                {data.map((val, index) => (
                    <div key={val.id}>
                        <OverlayView
                            key={val.id}
                            position={{ lat: val.lat, lng: val.lng }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                            <ButtonUnstyled
                                onClick={() => setActive(val)}
                                style={{ position: 'absolute', transform: 'translate(-50%, -100%)', textAlign: 'center' }}>
                                <Marker
                                    draggable={false}
                                    animation={google.maps.Animation.DROP}
                                    icon={{
                                        url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR42mP8/wcAAwAB/AB1gYAAAAABJRU5ErkJggg==',
                                        // Transparent 1x1 pixel image
                                    }}
                                />
                                <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${val.agent?.userId}`}>
                                    <AttachmentSvs module={"at-9"} path={val.agent?.profilePicPath} data={val} />
                                </Link>
                            </ButtonUnstyled>
                        </OverlayView>
                        {activeMission.id == val.id && (
                            <InfoWindow
                                options={{
                                    gestureHandling: "greedy",
                                    pixelOffset: new google.maps.Size(0, -50),

                                }}
                                disableAutoPan={true}
                                position={val.latLang}
                                visible={activeMission.id == val.id}
                            >
                                <PopupStyled>
                                    <div id="box-content" className=" min-h-136">
                                        <CardHeader
                                            className=" !pl-88 !pt-10 !pr-16"
                                            action={
                                                <>

                                                </>
                                            }
                                            title={
                                                <>
                                                    <Typography className=" text-16 font-bold">
                                                        <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${val.agent?.userId}`}>
                                                            {val.agent?.flName}
                                                        </Link>
                                                    </Typography>
                                                </>
                                            }
                                            subheader={
                                                val.kingPin === 1
                                                    ? (val.dateCreated && <DateTimeDisplay start={"Created mission about"} timeago={val.timeAgo} />)
                                                    : (val.dateCreated && <DateTimeDisplay start={"Joined mission about"} timeago={val.timeAgo} />)
                                            }

                                        />
                                        <Typography className=" !p-16 !pb-0 text-18 font-extrabold">
                                            {val.title}
                                        </Typography>
                                        <div className="pl-16 pr-16 pb-16">
                                            {/* <TextLimit text={val.description} limit={100} /> */}
                                            <Container style={{ maxHeight: 230 }} className=" min-w-0 p-0" sx={{ '& .image-container': { paddingBottom: '40% !important' } }}>
                                                <PhotoGridView single={true} fileId={val.filesId} />
                                            </Container>
                                        </div>
                                    </div>
                                    <div id="box-profile" className=" flex justify-center items-center">
                                        <AttachmentSvs module={"at-8"} path={val.agent?.profilePicPath} data={val} />
                                    </div>
                                </PopupStyled>

                            </InfoWindow>
                        )}
                    </div>
                ))}

            </GoogleMap>
        </div>
    ) : <></>
}

export default MissionDetailMapContent