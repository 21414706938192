import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { updateDislikes, updateLikes } from '../store/FriendsActivitySlice';
import { useNavigate } from 'react-router-dom';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { addNotification } from "app/fuse-layouts/shared-components/quickPanel/store/dataSlice";
import notification from 'app/services/notification';
import moment from "moment";

const LikeButton = ({ missionId, userId, flName, initialAction, reloadActivities, likers, link }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = AgentJwtService.getDecodedAccessToken();
    const liker = token.id;
    const likersArray = likers ? likers.split(",") : [];
    const likersCount = likersArray.length;
    const [action, setAction] = useState(likersArray.includes(liker) ? 'like' : 'unlike');

    function createNotification(obj) {
        dispatch(addNotification(obj));
    }

    const handleLikeToggle = useCallback(() => {
        setAction((prevState) => {
            const newState = prevState == 'like' ? 'unlike' : 'like';

            dispatch(updateLikes({ liker, userId, module: "missions", missionId, action: newState }))
                .then((result) => {
                    console.log(result.payload);
                    reloadActivities();
                    if (result.payload.status == 'success' && newState == 'like') {
                        createNotification({
                            uid: token.id,
                            uname: token.data.displayName,
                            upicPath: token.data.profilePicPath,
                            userId: userId,
                            userName: flName,
                            link: link,
                            message: notification({
                                uname: token.data.displayName,
                                username: flName
                            }, 'mission', 'like'),
                            module: 'mission',
                            time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                            status: "Unread"
                        })
                    }
                })
                .catch((error) => {
                    console.error('Error updating like status:', error);
                    setAction(!newState);
                });

            return newState;
        });
    }, [dispatch, missionId, userId, navigate, reloadActivities]);

    return (
        <>
            <IconButton
                size="small"
                onClick={handleLikeToggle}
                className="-mt-2"
            >
                {likersArray.includes(liker) ? (
                    <FavoriteIcon fontSize="inherit" stroke="black"
                        strokeWidth="1" className="favorite-icon-color" sx={{ color: 'red' }} />
                ) : (
                    <FavoriteBorderIcon fontSize="inherit" className="favorite-border-icon-color" sx={{ color: 'black' }} />
                )}
            </IconButton>
            <Typography className="h-fit font-medium ${from == 'mission' ? 'text-white' : 'text-black' }">
                {/* {count} */}
                {likersCount + '  ' + 'Likes'}
            </Typography>
        </>
    );
};
export default LikeButton;