import React, { useState } from "react";
import FriendsManager from "./content/FriendsManager";
import { Stack, Typography } from "@mui/material";
import CustomRoot from "../custom-component/CustomRoot";
class FriendManagerBg extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="271.628" height="249.842" viewBox="0 0 271.628 249.842">
                <g id="Group_736" data-name="Group 736" transform="translate(0 0)">
                    <path id="Path_339" data-name="Path 339" d="M769.661,151.24h-1.307V115.392a20.748,20.748,0,0,0-20.748-20.748H671.653A20.748,20.748,0,0,0,650.9,115.392V312.059a20.748,20.748,0,0,0,20.748,20.748H747.6a20.748,20.748,0,0,0,20.748-20.748v-135.3h1.307Z" transform="translate(-498.034 -94.644)" fill="#3f3d56" />
                    <path id="Path_340" data-name="Path 340" d="M772.525,121.193V320.639A15.736,15.736,0,0,1,756.8,336.373H679.285a15.739,15.739,0,0,1-15.741-15.736V121.193a15.738,15.738,0,0,1,15.74-15.733h9.4a7.482,7.482,0,0,0,6.922,10.3H739.8a7.483,7.483,0,0,0,6.922-10.3h10.072a15.736,15.736,0,0,1,15.733,15.728Z" transform="translate(-506.437 -101.835)" fill="#fff" />
                    <path id="Path_45" data-name="Path 45" d="M762.583,258.419a33.276,33.276,0,0,1-33.288-33.26v-.029c0-.07,0-.143,0-.213a33.288,33.288,0,1,1,33.286,33.5h0Zm0-65.877A32.657,32.657,0,0,0,730,224.938c0,.074,0,.134,0,.191a32.592,32.592,0,1,0,32.592-32.588Z" transform="translate(-550.155 -159.269)" fill="#e6e6e6" />
                    <path id="Path_39" data-name="Path 39" d="M792.162,456H723.285a1.277,1.277,0,0,1-1.275-1.275V437.639a1.277,1.277,0,0,1,1.275-1.275h68.876a1.277,1.277,0,0,1,1.275,1.275v17.085A1.277,1.277,0,0,1,792.162,456Zm-68.876-19.126a.766.766,0,0,0-.765.765v17.085a.766.766,0,0,0,.765.765h68.876a.766.766,0,0,0,.765-.765V437.639a.766.766,0,0,0-.765-.765Z" transform="translate(-545.311 -321.852)" fill="#e6e6e6" />
                    <path id="Path_40" data-name="Path 40" d="M787.608,455a.893.893,0,0,0,0,1.786H829.7a.893.893,0,1,0,.068-1.785H787.608Z" transform="translate(-588.333 -334.245)" fill="#e6e6e6" />
                    <path id="Path_41" data-name="Path 41" d="M787.608,470.99a.893.893,0,0,0,0,1.786H829.7a.893.893,0,1,0,.068-1.785H787.608Z" transform="translate(-588.333 -344.874)" fill="#e6e6e6" />
                    <path id="Path_42" data-name="Path 42" d="M792.162,541.259H723.285a1.277,1.277,0,0,1-1.275-1.275V522.9a1.277,1.277,0,0,1,1.275-1.275h68.876a1.277,1.277,0,0,1,1.275,1.275v17.085a1.277,1.277,0,0,1-1.275,1.275Zm-68.876-19.126a.766.766,0,0,0-.765.765v17.085a.766.766,0,0,0,.765.765h68.876a.766.766,0,0,0,.765-.765V522.9a.766.766,0,0,0-.765-.765Z" transform="translate(-545.311 -377.861)" fill="#e6e6e6" />
                    <path id="Path_43" data-name="Path 43" d="M787.608,540.26a.893.893,0,1,0,0,1.786H829.7a.893.893,0,1,0,.029-1.786h-42.12Z" transform="translate(-588.333 -390.932)" fill="#e6e6e6" />
                    <path id="Path_44" data-name="Path 44" d="M787.608,556.251a.893.893,0,1,0,0,1.786H829.7a.893.893,0,1,0,.029-1.786h-42.12Z" transform="translate(-588.333 -401.564)" fill="#e6e6e6" />
                    <path id="Path_39-2" data-name="Path 39-2" d="M792.162,626.517H723.285a1.277,1.277,0,0,1-1.275-1.275V608.156a1.277,1.277,0,0,1,1.275-1.275h68.876a1.277,1.277,0,0,1,1.275,1.275v17.085a1.277,1.277,0,0,1-1.275,1.275Zm-68.876-19.126a.766.766,0,0,0-.765.765v17.085a.766.766,0,0,0,.765.765h68.876a.766.766,0,0,0,.765-.765V608.156a.766.766,0,0,0-.765-.765Z" transform="translate(-545.311 -435.228)" fill="#e6e6e6" />
                    <path id="Path_40-2" data-name="Path 40-2" d="M787.608,625.521a.893.893,0,1,0,0,1.786H829.7a.893.893,0,0,0,.068-1.785H787.608Z" transform="translate(-588.333 -447.621)" fill="#e6e6e6" />
                    <path id="Path_41-2" data-name="Path 41-2" d="M787.608,641.508a.893.893,0,1,0,0,1.786H829.7a.893.893,0,0,0,.068-1.785H787.608Z" transform="translate(-588.333 -458.251)" fill="#e6e6e6" />
                    <path id="Path_1221" data-name="Path 1221" d="M818.286,269.5h-9.708V259.8H806.96V269.5h-9.708v1.618h9.708v9.708h1.618v-9.708h9.708Z" transform="translate(-595.339 -204.452)" fill="#fbb633" />
                    <g id="Group_76" data-name="Group 76" transform="translate(45.274 63.283)">
                        <path id="Path_1236" data-name="Path 1236" d="M394.138,653.078s-7.938,8.694-9.072,28.728-1.512,22.3-1.512,22.3H411.9l5.289-13.606,6.049,13.605H450.08s3.024-41.579,0-42.713S394.138,653.078,394.138,653.078Z" transform="translate(-365.547 -517.548)" fill="#2f2e41" />
                        <circle id="Ellipse_232" data-name="Ellipse 232" cx="13.608" cy="13.608" r="13.608" transform="translate(52.971 6.067)" fill="#ffb8b8" />
                        <path id="Path_1238" data-name="Path 1238" d="M638.649,527.46l-20.79-37.8L605.762,470l14.365-6.427,7.938,15.5s10.584,15.5,13.23,28.35,5.292,18.9,5.292,18.9Z" transform="translate(-513.292 -391.55)" fill="#ffb8b8" />
                        <path id="Path_1239" data-name="Path 1239" d="M348.616,524l31-27.215-1.51-21.924h-8.694L366.76,486.2l-24.948,32.885Z" transform="translate(-337.793 -399.049)" fill="#ffb8b8" />
                        <path id="Path_1240" data-name="Path 1240" d="M433.687,365.618s11.226,4.706,19.6-1.049c0,0,20.469,3.318,21.6,4.451s-1.134,35.152-1.134,35.152l-4.535,19.279s6.426,29.484-3.024,37.043l1.89,10.206s-41.957,3.024-58.589-7.182l3.023-10.207,7.938-32.129-4.914-44.6S419.322,366.374,433.687,365.618Z" transform="translate(-382.796 -325.719)" fill="#c2c2c2" />
                        <path id="Path_1241" data-name="Path 1241" d="M571.374,379.928l9.828-1.511s4.914.756,9.45,10.206,9.45,21.924,9.45,21.924l-17.01,7.56-13.986-21.924Z" transform="translate(-488.921 -334.927)" fill="#c2c2c2" />
                        <path id="Path_1242" data-name="Path 1242" d="M423.812,394.771l-2.268,1.89s-3.024,10.962-3.024,18.522v10.206l13.608,1.512Z" transform="translate(-388.796 -345.801)" fill="#c2c2c2" />
                        <path id="Path_1243" data-name="Path 1243" d="M484.068,272a6.667,6.667,0,0,1-1.662-4.5,14.258,14.258,0,0,0-.27-4.251,41.976,41.976,0,0,1,1.434-6.465c.855-2.373.134-3.2,3.809-4.493s1.335-5.979,12.169-2.245a2.964,2.964,0,0,0,3.223.659c2-.733,3.206,2.015,3.206,2.015s1.248-1.024,1.948.041,4.086-1.361,5.328,6.381-3.021,14.646-3.021,14.646-.6-12.389-7.634-12.614-14.993-2.89-15.979,2.327A38.036,38.036,0,0,1,484.068,272Z" transform="translate(-431.094 -248.636)" fill="#2f2e41" />
                        <circle id="Ellipse_80" data-name="Ellipse 80" cx="5.864" cy="5.864" r="5.864" transform="translate(0 119.007)" fill="#ffb8b8" />
                    </g>
                    <path id="Path_1259" data-name="Path 1259" d="M205.144,620.989l96.582-19.279-8.071-40.434-96.582,19.278Z" transform="translate(-196.283 -393.226)" fill="#fff" />
                    <path id="Path_1260" data-name="Path 1260" d="M300.949,600.668l-97.9,19.543-8.335-41.754,97.9-19.542ZM204.1,618.63l95.265-19.016L291.56,560.5,196.3,579.513Z" transform="translate(-194.714 -391.656)" fill="#e4e4e4" />
                    <g id="Group_77" data-name="Group 77" transform="translate(20.608 179.723)">
                        <path id="Path_1230" data-name="Path 1230" d="M275.956,646.706l-.04.017c-.315.13-.634.252-.962.36-.11.037-.224.072-.335.106s-.225.069-.34.1c-.187.054-.378.1-.57.145-.082.021-.169.039-.252.057l-.05.011c-.1.022-.206.044-.309.062l-.268.047c-.05.011-.1.018-.148.025a14.261,14.261,0,1,1,2.216-27.646,14.261,14.261,0,0,0,5.134,24.139,14.2,14.2,0,0,1-4.076,2.58Z" transform="translate(-256.211 -611.518)" fill="#fbb633" />
                        <path id="Path_1231" data-name="Path 1231" d="M332.542,631.752a13.92,13.92,0,0,0,1.932.793,14.185,14.185,0,0,1-6.575,3.363l-.179.038a14.262,14.262,0,1,1,1.62-27.54,14.234,14.234,0,0,0,3.2,23.345Z" transform="translate(-292.408 -603.817)" fill="#fbb633" />
                        <path id="Path_1232" data-name="Path 1232" d="M393.346,607.407a14.261,14.261,0,1,1-16.916-10.982h0A14.261,14.261,0,0,1,393.346,607.407Z" transform="translate(-328.633 -596.11)" fill="#fbb633" />
                        <path id="Path_1233" data-name="Path 1233" d="M406.918,639.675a9.556,9.556,0,0,1,.21,1.92,14.288,14.288,0,0,1-10.913,2.321,9.556,9.556,0,0,1-.589-1.84c-.7-3.281.384-6.41,2.487-7.826a4.414,4.414,0,1,1,3.348-.712C403.959,633.976,406.22,636.394,406.918,639.675Z" transform="translate(-348.176 -615.652)" fill="#fff" />
                        <path id="Path_1234" data-name="Path 1234" d="M351.408,651.44l.009.043a14.575,14.575,0,0,1,.162,2.4,13.3,13.3,0,0,1-4.993,1.756l-.179.038a14.207,14.207,0,0,1-5.693.051,1.475,1.475,0,0,1-.067-.166,9.142,9.142,0,0,1-.522-1.673c-.7-3.281.384-6.41,2.488-7.825a4.413,4.413,0,0,1-.091-8.653,4.267,4.267,0,0,1,1.029-.094,14.246,14.246,0,0,0,7.858,14.128Z" transform="translate(-311.274 -623.505)" fill="#fff" />
                        <path id="Path_1235" data-name="Path 1235" d="M297.93,663.065a9.162,9.162,0,0,1,.176,1.726,14.213,14.213,0,0,1-3.675,1.851c-.11.037-.224.072-.335.106s-.225.069-.34.1c-.187.054-.378.1-.57.145-.039.012-.082.021-.122.029l-.179.038c-.1.022-.206.044-.309.062l-.268.047c-.05.011-.1.018-.148.024a14.132,14.132,0,0,1-4.968-.082.057.057,0,0,1-.008-.023,1.1,1.1,0,0,1-.058-.144,9.138,9.138,0,0,1-.522-1.673c-.7-3.281.384-6.41,2.487-7.825a4.413,4.413,0,0,1-.091-8.653,4.317,4.317,0,0,1,.727-.092,14.246,14.246,0,0,0,8.2,14.363Z" transform="translate(-275.688 -631.078)" fill="#fff" />
                    </g>
                    <path id="Path_1265" data-name="Path 1265" d="M749.618,457.811a4.773,4.773,0,0,1-.1.961,7.145,7.145,0,0,1-5.58,0,4.782,4.782,0,0,1-.1-.961,3.757,3.757,0,0,1,2.031-3.569,2.207,2.207,0,1,1,1.711,0A3.756,3.756,0,0,1,749.618,457.811Z" transform="translate(-559.829 -330.918)" fill="#fbb633" />
                    <path id="Path_1266" data-name="Path 1266" d="M749.618,544.8a4.782,4.782,0,0,1-.1.961,7.145,7.145,0,0,1-5.579,0,4.773,4.773,0,0,1-.1-.961,3.757,3.757,0,0,1,2.031-3.569,2.207,2.207,0,1,1,1.711,0A3.756,3.756,0,0,1,749.618,544.8Z" transform="translate(-559.829 -388.755)" fill="#fbb633" />
                    <path id="Path_1267" data-name="Path 1267" d="M749.618,630.055a4.781,4.781,0,0,1-.1.961,7.145,7.145,0,0,1-5.579,0,4.772,4.772,0,0,1-.1-.961,3.757,3.757,0,0,1,2.031-3.569,2.207,2.207,0,1,1,1.711,0A3.756,3.756,0,0,1,749.618,630.055Z" transform="translate(-559.829 -445.442)" fill="#fbb633" />
                    <circle id="Ellipse_81" data-name="Ellipse 81" cx="5.362" cy="5.362" r="5.362" transform="translate(169.598 195.359)" fill="#ffb8b8" />
                </g>
            </svg>


        )
    }
}
function PublicFriendsManager(props) {
    return (
        <CustomRoot>
            <Stack direction={'row'} className=" bg-pi-blue-2 items-end justify-around w-full px-16 h-288  max-600px:h-128 max-600px:justify-start"  >
                <div className="mb-32">
                    <Typography variant="h4" className=" font-extrabold">Friend Manager</Typography>
                    <Typography variant="h5">Turn off/on notifications and unfollow</Typography>
                </div>
                <div className=" max-600px:hidden block">
                    <FriendManagerBg />
                </div>
            </Stack>
            <FriendsManager />
        </CustomRoot>
    );
}

export default PublicFriendsManager;
