// * Updated By: Jherome
// * Summary:  remove z-index value
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-44189-96
// * DateUpdated:  01/04/2023

import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import PoweredByLinks from 'app/fuse-layouts/shared-components/PoweredByLinks';
import PurchaseButton from 'app/fuse-layouts/shared-components/PurchaseButton';
import DocumentationButton from 'app/fuse-layouts/shared-components/DocumentationButton';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-0 shadow-md', props.className)}
        color="default"
        style={{ backgroundColor: footerTheme.palette.background.paper }}
      >
        <Toolbar className="min-h-36 md:h-36 px-8 sm:px-12 py-0 flex flex-col justify-center items-center overflow-x-auto">
          <Typography className="FuseSettings-formGroupTitle" color="textSecondary">
            Copyright © 2025 Planet Impossible Inc. All rights reserved.
          </Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(FooterLayout1);
