// import { styled } from "@mui/material/styles";
// import React, { useState, useRef, useEffect, useCallback } from "react";
// import { inputLabelClasses } from "@mui/material/InputLabel";
// import Card from "@mui/material/Card";
// import Typography from "@mui/material/Typography";
// import { FiSearch, FiBell } from "react-icons/fi";
// import {
//     Avatar,
//     Box,
//     CardActionArea,
//     Divider,
//     Icon,
//     IconButton,
//     Link,
//     InputAdornment,
// } from "@mui/material";
// import { TextField, Button, Item, CardHeader } from "@mui/material";
// import Stack from "@mui/material/Stack";
// import { useDispatch, useSelector } from "react-redux";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import ScrollToBottomTrigger from "app/services/ScrollToBottomTrigger";
// import { useLocation } from "react-router-dom";
// import { debounce } from 'lodash';
// import SkeletonLoader from "../components/SkeletonLoader";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { TextField, Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon, IconButton, Popover, Skeleton, TextareaAutosize } from "@mui/material";
import { cloneElement, memo, useCallback } from 'react';
import PublicSidebarLeft from "app/main/apps/public/public-sidebar-left/PublicSidebarLeft";
import { useDispatch, useSelector } from "react-redux";
import { Background, Parallax } from "react-parallax";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import { useEffect, useState, useRef } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import FuseLoading from "@fuse/core/FuseLoading";
import SharerSvs from "app/services/SharerSvs";
import { position } from "stylis";
import { PinIcon, SeenIcon, CommentIcon, PinnedIcon, PinnedIconBlue, LikesIcon, CommunityIconBadge } from "../../../../custom-icon/MissionCounterIcons"
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { saveCommunity, getCommunityList } from "../../../public/community/store/CommunityManageSlice";
import AttachmentUploader from "app/services/AttachmentUploader";
import randomString from "app/services/randomString";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Delete } from "@mui/icons-material";
import PhotoGridView from "app/services/PhotoGridView";

import AgentJwtService from 'app/services/agent/AgentJwtService';

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#FAFAFB",
        border: "1px solid #E9E9E9",
        height: 40,
        width: '100%',
        minWidth: '0px',
        fontSize: '16px'
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiFilledInput-input": {
        paddingTop: "8px"
    },
    "& input#mui-1::placeholder": {
        color: "#8B8B8B",
        opacity: 'unset',
        fontWeight: 'semi-bold',
        fontSize: '16px'


    },

}));
const CommunityPostForm = styled('div')(() => ({
    width: '100%',
    "& .custom-textarea": {
        width: '100%',
        resize: 'none',
        borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
        padding: '8px',
        marginTop: '5px',
        outline: "none",
        minHeight: '25px'
    },
    "& .custom-textarea:focus": {
        borderBottom: "1.5px solid black",
        transition: "border - color 0.3s",
    }
}));
const defaultValues = {
    id: null,
    title: "",
    featuredImageId: "",
    images: []
};


const token = AgentJwtService.getDecodedAccessToken();
function PublicCommunityContent(props) {
    const initial = 10;
    const [data, setData] = useState([]);
    const pageLayout = useRef(null);
    const dispatch = useDispatch();
    const [blogs, setBlogs] = useState([]);
    const [author, setAuthor] = useState('all_author');
    const [category, setCategory] = useState('all_post');
    const [authorList, setAuthorList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [keywordTemp, setKeywordTemp] = useState("");
    const [loading, setLoading] = useState(true);
    const [isLoadingBlogs, setIsLoadingBlogs] = useState(true);
    const [range, setRange] = useState([0, initial]);
    const [hasLoadMore, setHasLoadMore] = useState(true);
    const [attachmentTempId, setAttachmentTempId] = useState(randomString())
    const [attachmentData, setAttachmentData] = useState([])
    const [attachmentLoader, setAttachmentLoader] = useState(true)
    const [missionLoading, setmissionLoading] = useState(true);
    // const [reset, setReset] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [communityList, setcommunityList] = useState([]);

    useEffect(() => {
        setAttachmentTempId(randomString())
        setAttachmentLoader(false)
        setLoading(true);
        const agentId = queryParams.get("agentId") || token.id;
        dispatch(getCommunityList(agentId)).then((communityAction) => {
            console.log("getCommunityList", communityAction.payload);
            setcommunityList(communityAction.payload);
        });

        // Set loading states to false after all data is fetched
        setLoading(false);
        setmissionLoading(false);
    }, [dispatch]);



    const schema = yup.object().shape({
        title: yup.string()
            .required("Title is required")
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        watch,
        trigger
    } = methods;

    async function handleSave() {
        // Trigger form validation
        const isValid = await trigger();

        if (isValid) {
            const agentId = queryParams.get("agentId") || token.id;
            console.log('Form data:', getValues());
            var data = getValues();

            // Check if the title (post content) is not empty
            if (data.title && data.title.trim()) {
                data.fileId = attachmentTempId;
                data.userId = token.id;

                dispatch(saveCommunity(data)).then((action) => {
                    console.log('action.payload', action.payload);

                    dispatch(getCommunityList(agentId)).then((communityAction) => {
                        console.log("getCommunityList", communityAction.payload);
                        setcommunityList(communityAction.payload);
                    });

                    setLoading(false);
                    reset(defaultValues);
                    setAttachmentTempId(randomString());
                    setAttachmentData([]);
                });
            } else {
                console.log('Cannot save empty post');
                // Optionally, you can set an error state or show a message to the user
            }
        } else {
            console.log('Form validation failed');
            // The form will display validation errors automatically
        }
    }


    return (
        <>
            <Stack direction={'column'} gap={{ lg: 3, md: 1.5, sm: 1.5, xs: 1.5 }} className="font-nunito px-0 max1200px:py-28 py-16 m-auto  max1200px:max-w-lg md:w-10/12 sm:w-full w-full">
                <Card className="p-16 pb-8 rounded-lg mt-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                    <Stack gap={1} direction={'row'}>
                        <div className="relative">
                            <Badge
                                className="mt-6"
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                sx={{
                                    '& .MuiBadge-anchorOriginBottomRightCircular': {

                                        bottom: '18%',

                                    }
                                }}
                                badgeContent={
                                    <span
                                    >
                                        <CommunityIconBadge />

                                    </span>
                                }
                            >
                                <Avatar className="border-1 border-pi-red" style={{ height: '46px', width: '46px' }}></Avatar>
                            </Badge>

                        </div>
                        <CommunityPostForm >
                            <Stack gap={1} flex={1}>
                                <FormProvider {...methods}>
                                    <Controller
                                        name="title"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Post content is required' }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <TextareaAutosize
                                                    {...field}
                                                    maxRows={4}
                                                    className="custom-textarea text-16 leading-tight"
                                                    placeholder="What's the latest on your mind?!"
                                                />
                                                {error && <p className="text-red-500 text-sm">{error.message}</p>}
                                            </>
                                        )}
                                    />
                                    <Stack direction={'row'} justifyContent={'space-between'} className="my-10">
                                        <AttachmentUploader module="community" id={attachmentTempId} attachmentData={attachmentData} setAttachmentData={setAttachmentData} />

                                        {/* <Stack direction={'row'} gap={1} className="mt-10" flexWrap={'wrap'}>
                                                                {attachmentLoader ? (<FuseLoading />) :
                                                                    attachmentData.length > 0 &&
                                                                    attachmentData.map((item) => (
                                                                        <
                                                                    ))
                                                                }

                                                            </Stack> */}
                                        {/* <p style={{ display: 'flex', alignItems: 'center' }} className="cursor-pointer text-pi-blue-1 text-16">
                                                                <Icon className="mr-6">attachments</Icon>
                                                                Attached files
                                                            </p>
                                                            */}
                                        {/* attachment */}
                                        <Button onClick={handleSave} variant="contained" color="primary" size="small" className="font-bold">
                                            Post
                                        </Button>
                                    </Stack>
                                    <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
                                        {attachmentLoader ? (<FuseLoading />) :
                                            attachmentData.length > 0 &&
                                            attachmentData.map((item) => (
                                                <Stack direction={'row'} gap={1.5} flexWrap={'wrap'}>
                                                    <div
                                                        style={{ width: 80, height: 80 }}
                                                        className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                                    >

                                                        <Icon className='text-red-600 cursor-pointer absolute top-0 right-5 z-20'
                                                            onClick={() => { handleDeleteAttachment(item.id) }}
                                                        ><Delete /> </Icon>
                                                        {
                                                            (() => {
                                                                switch (item.switchType) {
                                                                    case "youtube":
                                                                        return <div className="image-container">
                                                                            <DisabledPlayer>
                                                                                <div className=" z-10 disable"></div>
                                                                                <ReactPlayer
                                                                                    light={true}
                                                                                    playing={false}
                                                                                    className="react-player rounded-lg overflow-hidden "
                                                                                    url={item.srcUrl}
                                                                                    height={80}
                                                                                    width={80}
                                                                                />
                                                                            </DisabledPlayer>
                                                                        </div>;
                                                                    case "video":
                                                                        return <div className="image-container">
                                                                            <video autoPlay={false} className="blog">
                                                                                <source
                                                                                    type={item.fileType}
                                                                                    src={item.srcUrl}
                                                                                />
                                                                            </video>
                                                                        </div>;
                                                                    default:
                                                                        return <div className="image-container2">
                                                                            <img

                                                                                className="max-w-none w-full h-full object-cover"
                                                                                src={item.srcUrl}
                                                                            />
                                                                        </div>;
                                                                }
                                                            })()
                                                        }
                                                    </div>
                                                </Stack>
                                            ))
                                        }

                                    </Stack>
                                </FormProvider>
                            </Stack>
                        </CommunityPostForm >
                    </Stack>
                </Card>

                {loading ? (
                    <Card className="p-16 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                        <Typography className="text-center text-pi-grey">
                            Loading communities...
                        </Typography>
                    </Card>
                ) : communityList && communityList.length > 0 ? (
                    communityList.map((item, index) => (

                        <Card className="p-16 pb-8 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                            <CardHeader
                                className="p-0"
                                sx={{
                                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                    // "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                }}
                                avatar={
                                    <Badge
                                        className="mt-6"
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        sx={{
                                            '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                bottom: '18%',
                                            }
                                        }}
                                        badgeContent={
                                            <span>
                                                <CommunityIconBadge />
                                            </span>
                                        }
                                    >
                                        <Avatar className="border-1 border-pi-red" style={{ height: '46px', width: '46px' }}></Avatar>
                                    </Badge>
                                }
                                title={
                                    <Typography className=" font-extrabold text-18">
                                        {item.agent?.firstName}
                                    </Typography>
                                }
                                subheader={
                                    <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                        {"@" + item.agent?.username} · Posted {item.timeAgo}
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={`/public/community-details/${item.id}`}
                                        state={{ item: item }}
                                    >
                                        <Typography
                                            className="text-16 transition duration-300 line-clamp-3 my-4 paragraph contents"
                                        >
                                            {item.title}
                                        </Typography>
                                    </Link>
                                </Box>
                                <PhotoGridView fileId={item.fileId} moduleType={'community'} />
                            </CardContent>

                            <CardActions className="flex-col pt-2 pb-0 px-0 justify-start items-start">
                                <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                    <Typography className="gap-1 flex items-center">
                                        <SeenIcon />
                                        <span className="mt-1">{item.views} View{item.commentsCount !== 1 ? 's' : ''}</span>
                                    </Typography>

                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={`/public/community-details/${item.id}`}
                                        state={{ item: item }}
                                    >
                                        <Typography className="gap-1 flex items-center cursor-pointer">
                                            <CommentIcon />
                                            <span className="mt-1">{item.commentsCount} Comment{item.commentsCount !== 1 ? 's' : ''}</span>
                                        </Typography>
                                    </Link>

                                    {/* <Typography onClick={() => toggleExpand(item.id)} className="gap-1 flex items-center cursor-pointer">
                                                                <CommentIcon />
                                                                <span className="mt-1">{item.commentsCount} Comment{item.commentsCount !== 1 ? 's' : ''}</span>
                                                            </Typography> */}

                                    {/* <Typography className="gap-1 flex items-center">
                                                                <PinIcon />
                                                                <span className="mt-1">101 Pinned</span>
                                                            </Typography> */}
                                    <Box className="flex-1 text-right">
                                        <SharerSvs link={`/public/community-details/${item.id}`} title={item.title} description={""} />
                                    </Box>
                                </Stack>

                            </CardActions>
                            {/* {isExpandedrep && (
                                                        <Commentsv2 moduleType="communtiy" moduleId={item.missionId} />
                                                    )} */}

                            {/* {isExpandedrep[item.id] && (
                                                        <Commentsv2 moduleType="community" moduleId={item.id} />
                                                    )} */}
                        </Card>
                    ))
                ) : (
                    <Card className="p-16 pb-8 rounded-lg mb-18  shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                        <CardContent className="p-0">
                            <Typography
                                component="div"
                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                            >
                                No communities found.
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </Stack>
        </>
    );
}

export default PublicCommunityContent;
