const mutation = {};

const query = {
    getMissionById: `
        query getMissionById($id: String){
            getMissionById(id: $id){
            id
            title
            description
            dateCreated
            filesId
            views
            pinned
            markers{
                id
                title
                description
                dateCreated
                filesId
                lat
                lng
                kingPin
                agent{
                    userId
                    profilePicPath
                    name
                    flName
                    username
                }
                agentId
                missionId
                likers
            }
            commentsCount
            status
            agent{
                userId
                profilePicPath
                name
                flName
                username
            }
        }
    }
    `,
    getMarkersByMissionId: `
        query getMarkersByMissionId($id: String, $type: String){
            getMarkersByMissionId(id: $id, type: $type){
            id
            title
            description
            dateCreated
            filesId
            lat
            lng
            kingPin
            missionId
            missionViews
            missionPinned
            countOnCommentsById
            likers
            status
            agentId
            agent{
                userId
                profilePicPath
                name
                flName
                username
            }
            timeAgo
        }
    }
    `,
};

export { mutation, query };
