import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, ListSubheader, Box, Button, Icon, IconButton } from '@mui/material';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { agentById, getCounters } from '../navbar/store/navbarSlice';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function UserNavbarHeader(props) {
  const token = AgentJwtService.getDecodedAccessToken();
  const dispatch = useDispatch();
  const [data, setData] = useState('');
  const [userData, setUserData] = useState('');

  useEffect(() => {
    dispatch(getCounters(token.id)).then((action) => {
      setData(action.payload)
    });

  }, [dispatch])

  useEffect(() => {
    dispatch(agentById(token.id)).then((action) => {
      setUserData(action.payload)
      console.log('user data', action.payload)
    });

  }, [dispatch])

  const user = useSelector(({ auth }) => auth.user);
  return (
    <>
      {Object.keys(token).length > 0 ?
        <StyledAppBar
          position="static"
          color="primary"
          className=" bg-transparent user relative flex flex-col items-center justify-center mb-10 z-0 shadow-0 ">
          <div className=" pt-24 w-full">
            <div className="flex items-center">
              <Stack direction={'column'} alignItems={'center'} className="mt-10 w-full">
                <Link style={{ textDecoration: 'none' }} to={token.firstName ? `/agentprofile` : '/agentprofile/new-user?isNew=true'}>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    {userData?.profileUrl ? (
                      <Avatar sx={{ width: 40, height: 40 }} className=" border-1 border-pi-yellow-orange" alt="user photo" src={userData?.profileUrl} />
                    ) : (
                      <Avatar sx={{ width: 40, height: 40 }} className=" border-1 border-pi-yellow-orange"></Avatar>
                    )}

                  </Stack>
                </Link>
              </Stack>
            </div>
          </div>

        </StyledAppBar>
        :
        <Box className=" m-auto max-w-max min-w-fit pt-16">
          <IconButton component={Link} to="/agentlogin" className=" text-pi-blue-1 border-pi-text-grey hover:border-pi-text-grey rounded-full font-bold self-start"> <Icon className="text-[35px] text-pi-blue-1 fill-pi-blue-1">account_circle</Icon></IconButton>
        </Box>

      }

    </>
  );
}

export default UserNavbarHeader;
