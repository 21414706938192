import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import { GlobalStyles, height } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon } from "@mui/material";
import PublicSidebarLeft from "app/main/apps/public/public-sidebar-left/PublicSidebarLeft";
import { useDispatch, useSelector } from "react-redux";
import PublicSidebarLeftLogo from "app/main/apps/public/public-sidebar-left/PublicSidebarLeftLogo";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/agent/store';
import { Background, Parallax } from "react-parallax";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import { agentAccountById, updateAccount } from '../store/agentSlice';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useEffect, useState } from "react";
import { useForm, useFormContext, Controller, FormProvider } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import TakePhoto from "app/services/camera/TakePhoto";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import MenuItem from '@mui/material/MenuItem';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { showMessage } from "app/store/fuse/messageSlice";
import AgentProfileImage from "../modal/AgentProfileImage";
import AgentProfileBannerImage from "../modal/AgentProfileBannerImage";
import AgentProfilePassword from "../modal/AgentProfilePassword";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import CustomRoot from "../../public/custom-component/CustomRoot";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

const token = AgentJwtService.getDecodedAccessToken();
console.log(token, 'token');

const defaultValues = {
    id: token.id,
    email: "",
    username: "",
};

const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none"
    },

    "& .MuiInputLabel-root": {
        top: "0px",
    },
}));
function AgentProfileAccount(props) {
    const navigate = useNavigate();
    const schema = yup.object().shape({
        email: yup.string().required("Email Address is required"),
        username: yup.string().required("Username is required"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const [isSaving, setIsSaving] = useState(false);
    const { control, formState, setValue, watch, trigger, getValues } = methods;
    const { isValid, dirtyFields, errors } = formState;
    const n_id = watch('id');
    const n_username = watch('username');
    const n_webUpdate = 1;

    async function handleupdateAccount() {
        setIsSaving(true);
        const result = await trigger(["username"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        if (Object.keys(dirtyFields).length === 0) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );

        } else {
            dispatch(updateAccount({ id: n_id, username: n_username, webUpdate: n_webUpdate })).then((action) => {
                AgentJwtService.setUserRefreshToken();
                navigate("/agentprofile");
                setIsSaving(false);
            });
        }
    }

    localStorage.setItem('active_menu', 'Update Account');
    const [department, setDepartment] = useState([]);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(agentAccountById(token.id)).then((action) => {
            setData(action.payload);
            var pData = action.payload;
            console.log('action payload', action.payload);
            setValue('email', pData.email);
            setValue('username', pData.username);
        });
    }, [dispatch]);

    console.log(data, 'data');
    class ManageAccountBg extends React.Component {
        render() {
            return (
                <svg id="Group_1302" data-name="Group 1302" xmlns="http://www.w3.org/2000/svg" width="339.591" height="236.271" viewBox="0 0 339.591 236.271">
                    <ellipse id="Ellipse_73" data-name="Ellipse 73" cx="67.661" cy="5.139" rx="67.661" ry="5.139" transform="translate(0 225.994)" fill="#c2c2c2" />
                    <path id="Path_594" data-name="Path 594" d="M324.272,248.459c11.775-5.008,26.441-1.835,40.764.367.133-2.667,1.749-5.913.057-7.774-2.056-2.261-1.867-4.636-.63-7.025,3.164-6.109-1.369-12.609-5.946-18.014a10.136,10.136,0,0,0-8.46-3.551l-8.478.606a10.153,10.153,0,0,0-9.016,7.257h0c-2.024,2.753-3.108,5.5-2.426,8.23-3.032,2.072-3.542,4.573-2.179,7.393a4.25,4.25,0,0,1-.053,5.184,23.935,23.935,0,0,0-3.559,7.069Z" transform="translate(-272.553 -196.034)" fill="#2f2e41" />
                    <path id="Path_595" data-name="Path 595" d="M646.538,630.228H406.547a8.047,8.047,0,0,1-8.047-8.047h0q130.5-15.166,256.085,0h0a8.047,8.047,0,0,1-8.047,8.047Z" transform="translate(-314.994 -425.976)" fill="#2f2e41" />
                    <path id="Path_596" data-name="Path 596" d="M654.585,405.258,398.5,404.784l29.679-49.939.142-.237V244.549a10.172,10.172,0,0,1,10.173-10.172H613.171a10.172,10.172,0,0,1,10.172,10.173V355.366Z" transform="translate(-314.994 -208.579)" fill="#3f3d56" />
                    <path id="Path_597" data-name="Path 597" d="M486.926,250.957a3.317,3.317,0,0,0-3.314,3.313V354.621a3.317,3.317,0,0,0,3.314,3.313H663.488a3.318,3.318,0,0,0,3.314-3.313V254.27a3.317,3.317,0,0,0-3.314-3.314Z" transform="translate(-363.658 -218.058)" fill="#fff" />
                    <path id="Path_598" data-name="Path 598" d="M478.972,531.718a1.424,1.424,0,0,0-1.29.827l-9.145,19.881a1.42,1.42,0,0,0,1.29,2.014H662.143a1.42,1.42,0,0,0,1.27-2.055l-9.94-19.881a1.413,1.413,0,0,0-1.27-.785Z" transform="translate(-354.964 -378.588)" fill="#2f2e41" />
                    <circle id="Ellipse_74" data-name="Ellipse 74" cx="2.13" cy="2.13" r="2.13" transform="translate(208.708 26.981)" fill="#fff" />
                    <path id="Path_599" data-name="Path 599" d="M646.741,593.619a1.422,1.422,0,0,0-1.371,1.051l-2.294,8.52a1.42,1.42,0,0,0,1.371,1.789h43.626a1.42,1.42,0,0,0,1.341-1.885l-2.949-8.52a1.421,1.421,0,0,0-1.342-.955Z" transform="translate(-454.806 -413.981)" fill="#2f2e41" />
                    <path id="Path_600" data-name="Path 600" d="M459.47,337.135v1.893H264.306l.147-.236v-1.657Z" transform="translate(-151.121 -192.762)" fill="#2f2e41" />
                    <circle id="Ellipse_75" data-name="Ellipse 75" cx="33.135" cy="33.135" r="33.135" transform="translate(269.771 0)" fill="#fbb633" />
                    <path id="Path_601" data-name="Path 601" d="M903.706,258.047H876.251a1.9,1.9,0,0,1-1.893-1.893V239.586a1.9,1.9,0,0,1,1.893-1.893h27.455a1.9,1.9,0,0,1,1.893,1.893v16.567a1.9,1.9,0,0,1-1.893,1.893Zm-27.455-18.461v16.567h27.456V239.586Z" transform="translate(-587.072 -210.475)" fill="#fff" />
                    <path id="Path_602" data-name="Path 602" d="M907.979,219.362H890.938v-7.574c0-5.486,3.583-9.467,8.52-9.467s8.52,3.981,8.52,9.467Zm-15.147-1.893h13.254v-5.68c0-4.459-2.725-7.574-6.627-7.574s-6.627,3.114-6.627,7.574Z" transform="translate(-596.552 -190.25)" fill="#fff" />
                    <circle id="Ellipse_76" data-name="Ellipse 76" cx="1.893" cy="1.893" r="1.893" transform="translate(301.013 35.028)" fill="#fff" />
                    <path id="Path_603" data-name="Path 603" d="M671.259,369.343H534.806a2.53,2.53,0,0,1-2.527-2.527V332.968a2.53,2.53,0,0,1,2.527-2.527H671.259a2.53,2.53,0,0,1,2.527,2.527v33.848A2.53,2.53,0,0,1,671.259,369.343ZM534.806,331.452a1.518,1.518,0,0,0-1.516,1.516v33.848a1.518,1.518,0,0,0,1.516,1.516H671.259a1.518,1.518,0,0,0,1.516-1.516V332.968a1.518,1.518,0,0,0-1.516-1.516Z" transform="translate(-391.484 -263.505)" fill="#e6e6e6" />
                    <circle id="Ellipse_77" data-name="Ellipse 77" cx="10.293" cy="10.293" r="10.293" transform="translate(154.231 74.795)" fill="#e6e6e6" />
                    <path id="Path_604" data-name="Path 604" d="M640.912,356.8a1.716,1.716,0,0,0,0,3.431h80.876a1.716,1.716,0,1,0,0-3.431Z" transform="translate(-452.616 -278.579)" fill="#e6e6e6" />
                    <path id="Path_605" data-name="Path 605" d="M640.912,380.842a1.716,1.716,0,0,0,0,3.431h34.8a1.716,1.716,0,1,0,0-3.431Z" transform="translate(-452.616 -292.322)" fill="#e6e6e6" />
                    <path id="Path_606" data-name="Path 606" d="M432.917,362.827l-25.224,13.411-.319-11.175a74.7,74.7,0,0,0,23.309-7.663l2.638-6.518a4.418,4.418,0,0,1,7.51-1.147h0a4.418,4.418,0,0,1-.389,6.025Z" transform="translate(-320.067 -273.613)" fill="#ffb8b8" />
                    <path id="Path_607" data-name="Path 607" d="M322.865,540.687h0a4.956,4.956,0,0,1,.629-4.01l5.541-8.5A9.683,9.683,0,0,1,341.6,524.86h0c-2.329,3.964-2,7.441.8,10.463a50.372,50.372,0,0,0-11.963,8.156,4.956,4.956,0,0,1-7.574-2.791Z" transform="translate(-271.649 -374.047)" fill="#2f2e41" />
                    <path id="Path_608" data-name="Path 608" d="M407.635,487.565h0a9.508,9.508,0,0,1-8.081,4.616l-36.811.279-1.6-9.26,16.284-4.789L363.7,467.874l15.007-17.561,27.257,25.4a9.508,9.508,0,0,1,1.671,11.847Z" transform="translate(-293.636 -332.043)" fill="#2f2e41" />
                    <path id="Path_609" data-name="Path 609" d="M329.525,516.786H320.9c-7.74-53.058-15.619-106.263,7.663-126.122l27.459,4.47-3.512,23.309L337.188,436Z" transform="translate(-266.166 -297.938)" fill="#2f2e41" />
                    <path id="Path_610" data-name="Path 610" d="M345.425,692.879h0a4.956,4.956,0,0,1-4.01-.629l-9.361-1.258a9.683,9.683,0,0,1-3.315-12.562h0c3.964,2.329,7.441,2,10.463-.8,2.135,4.311,5.653,4.048,9.013,7.68a4.956,4.956,0,0,1-2.791,7.574Z" transform="translate(-274.488 -462.012)" fill="#2f2e41" />
                    <path id="Path_611" data-name="Path 611" d="M377.856,278.486l-15.965-3.832c2.652-5.427,2.87-11.467,1.6-17.881l10.856-.319A83.348,83.348,0,0,0,377.856,278.486Z" transform="translate(-294.063 -221.202)" fill="#ffb8b8" />
                    <path id="Path_612" data-name="Path 612" d="M369.508,346.769c-11.622,7.919-19.833.271-26.1-11.53.871-7.221-.539-15.862-3.15-25.252a17.188,17.188,0,0,1,10.494-20.727h0l13.73,5.747c11.658,9.5,13.953,19.8,9.579,30.652Z" transform="translate(-281.326 -239.96)" fill="#fbb633" />
                    <path id="Path_613" data-name="Path 613" d="M293.068,309.063,280.3,315.769l22.67,13.41,3.154,7.781a4.127,4.127,0,0,1-2.479,5.452h0a4.127,4.127,0,0,1-5.465-3.659l-.319-5.423-28.813-9.508a6.737,6.737,0,0,1-4.228-4.116h0a6.737,6.737,0,0,1,2.528-7.838l23.17-15.9Z" transform="translate(-238.331 -243.797)" fill="#ffb8b8" />
                    <path id="Path_614" data-name="Path 614" d="M338.125,305.839c-5.327-2.334-10.17.2-16.284,2.235l-.958-16.923c6.071-3.236,11.859-4.108,17.242-1.6Z" transform="translate(-270.615 -239.296)" fill="#fbb633" />
                    <circle id="Ellipse_78" data-name="Ellipse 78" cx="10.193" cy="10.193" r="10.193" transform="translate(63.688 23.249)" fill="#ffb8b8" />
                    <path id="Path_615" data-name="Path 615" d="M374.2,237.012c-10.092.988-17.782-.662-22.7-5.362v-3.785h21.84Z" transform="translate(-288.121 -204.856)" fill="#2f2e41" />
                </svg>

            )
        }
    }
    return (
        <FormProvider {...methods}>
            <CustomRoot>
                <Stack direction={'row'} className=" bg-pi-blue-2 items-end justify-around w-full px-16 h-288  max-600px:h-128 max-600px:justify-start"  >
                    <div className="mb-32">
                        <Typography variant="h4" className=" font-extrabold">Manage Your Account</Typography>
                        <Typography variant="h5">Customize your profile.</Typography>
                    </div>
                    <div className=" max-600px:hidden block">
                        <ManageAccountBg />
                    </div>
                </Stack>
                <Stack direction={'column'} gap={{ lg: 4, md: 2, sm: 2, xs: 2 }} className=" px-0 max1200px:py-40 py-20 m-auto max1200px:w-pi-655px-vw sm:w-4/5 w-full">
                    <Card className="p-24 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                        <Typography className=" text-18 font-bold ">
                            You may update your account.
                        </Typography>
                        <Stack gap={2}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">Email</Typography>}
                                            placeholder="Enter your first name"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56,
                                                    cursor: "not-allowed"
                                                }
                                                ,
                                                "& .MuiFilledInput-input": {
                                                    cursor: "not-allowed",
                                                    WebkitTextFillColor: '#48525C !important'
                                                }

                                            }}
                                            {...field}
                                            error={!!errors.email}
                                            required
                                            disabled
                                            helperText={errors?.email?.message}
                                            id="email"
                                            fullWidth
                                        />
                                    </div>
                                )}
                            />
                            <Controller
                                name="username"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">Username</Typography>}
                                            placeholder="Enter your first name"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56
                                                }
                                            }}
                                            {...field}
                                            className=""
                                            id="username"
                                            fullWidth
                                        />
                                    </div>
                                )}
                            />
                            <AgentProfilePassword item={data} module="edit" loadData={data} />
                            <Button onClick={handleupdateAccount} variant="contained" color="primary"
                                className=" mt-16 px-24 py-8 text-16 rounded-full font-bold w-full bg-pi-yellow-green">
                                UPDATE
                            </Button>
                        </Stack>
                    </Card>
                </Stack>

            </CustomRoot>
        </FormProvider>
    );
}

export default withReducer('agentApp', reducer)(AgentProfileAccount);