import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import { GlobalStyles, height } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon } from "@mui/material";
import PublicSidebarLeft from "app/main/apps/public/public-sidebar-left/PublicSidebarLeft";
import { useDispatch, useSelector } from "react-redux";
import PublicSidebarLeftLogo from "app/main/apps/public/public-sidebar-left/PublicSidebarLeftLogo";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/agent/store';
import { Background, Parallax } from "react-parallax";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import { agentById, updateAgent } from '../store/agentSlice';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useEffect, useState } from "react";
import { useForm, useFormContext, Controller, FormProvider } from "react-hook-form";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import TakePhoto from "app/services/camera/TakePhoto";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import MenuItem from '@mui/material/MenuItem';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { showMessage } from "app/store/fuse/messageSlice";
import AgentProfileImage from "../modal/AgentProfileImage";
import AgentProfileBannerImage from "../modal/AgentProfileBannerImage";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import CustomRoot from "../../public/custom-component/CustomRoot";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
}));
const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        // height: 56,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none"
    },

    "& .MuiInputLabel-root": {
        top: "0px",
    },
}));
const FormControlStyled = styled(FormControl)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff !important",
        border: "1px solid #141F2C",
        borderRadius: "8px"
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
        border: "unset",
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none",
    },

    "& .MuiInputLabel-root": {
        top: "0px",
        fontSize: "15px",
        color: '#48525C'
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: '#48525C'
    },
    "& .MuiSelect-select.MuiSelect-filled": {
        paddingTop: '20px'
    },
    "& .MuiSelect-nativeInput": {
        paddingTop: '3px'
    },
}));

function AgentProfileEdit(props) {
    const token = AgentJwtService.getDecodedAccessToken();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isNew = queryParams.get('isNew');
    const defaultValues = {
        id: token.id,
        firstName: "",
        lastName: "",
        middleName: "",
        gender: "",
        birthdate: null,
        country: "",
        city: "",
        state: "",
        about: "",
        profilePicId: "",
        profilePicPath: token.data.profilePicPath,
        images: [],
        bannerImages: [],
    };
    const navigate = useNavigate();

    useEffect(() => {
        if (isNew) {
            navigate("/agentprofile/edit");
            return dispatch(
                showMessage({
                    message: "Fill in the profile information first.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    })

    const [state, setState] = useState("");
    const [states, setStates] = useState([]);
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [countries, setCountries] = useState([]);

    const schema = yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        country: yup.string().required("Country is required"),
        state: yup.string().required("State is required"),
    });
    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });
    const { control, formState, setValue, watch, trigger, getValues } = methods;
    const { isValid, dirtyFields, errors } = formState;
    const wCountry = watch("country");

    useEffect(() => {
        console.log(CountryRegionData, wCountry, 'console')
        if (wCountry) {
            var i = CountryRegionData.filter((i) => {

                return i[0] == wCountry;
            });
            console.log('i', i);
            setStates((i[0][2].split('|')));
        }
    }, [wCountry]);

    async function handleupdateAgent() {
        setIsSaving(true);
        const result = await trigger(["firstName", "lastName", "country", "state", "city"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        if (Object.keys(dirtyFields).length === 0) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );

        } else {
            const data = getValues();
            data.birthdate = format(new Date(data.birthdate), 'yyyy-MM-dd');
            data.webUpdate = '1';
            const mdata = data;
            console.log(mdata, 'asojidnasd');
            dispatch(updateAgent(mdata)).then((action) => {
                AgentJwtService.setUserRefreshToken();
                navigate("/agentprofile");
                setIsSaving(false);
            });
        }
    }

    localStorage.setItem('active_menu', 'Edit Profile');
    const [types, setTypes] = useState([]);
    const [department, setDepartment] = useState([]);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [takePhotoModal, setTakePhotoModal] = useState(false);

    useEffect(() => {
        dispatch(agentById(token.id)).then((action) => {
            setData(action.payload);
            var pData = action.payload;
            console.log('action payload', action.payload);
            setValue('city', pData.city);
            setValue('about', pData.about);
            setValue('state', pData.state);
            setValue('gender', pData.gender);
            setValue('country', pData.country);
            setValue('lastName', pData.lastName);
            setValue('birthdate', pData.birthdate);
            setValue('firstName', pData.firstName);
            setValue('middleName', pData.middleName);
            setValue('webUpdate', pData.webUpdate);
            setValue("images", pData.images ? [pData.images] : []);
            setValue("bannerImages", pData.bannerImages ? [pData.bannerImages] : []);
            if (!pData.firstName) {
                return dispatch(
                    showMessage({
                        message: "Fill in the profile information first.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        });
    }, [dispatch]);

    console.log(data, 'data');
    class ManageProfileBg extends React.Component {
        render() {
            return (
                <svg id="Group_1300" data-name="Group 1300" xmlns="http://www.w3.org/2000/svg" width="339.529" height="226" viewBox="0 0 339.529 226">
                    <rect id="Rectangle_62" data-name="Rectangle 62" width="312.529" height="148.3" transform="translate(0.133 8.568)" fill="#c2c2c2" />
                    <rect id="Rectangle_75" data-name="Rectangle 75" width="294.656" height="123.732" transform="translate(9.07 21.285)" fill="#fff" />
                    <rect id="Rectangle_80" data-name="Rectangle 80" width="312.529" height="13.277" fill="#fbb633" />
                    <circle id="Ellipse_90" data-name="Ellipse 90" cx="2.461" cy="2.461" r="2.461" transform="translate(7.406 4.464)" fill="#fff" />
                    <circle id="Ellipse_91" data-name="Ellipse 91" cx="2.461" cy="2.461" r="2.461" transform="translate(16.746 4.464)" fill="#fff" />
                    <circle id="Ellipse_92" data-name="Ellipse 92" cx="2.461" cy="2.461" r="2.461" transform="translate(26.087 4.464)" fill="#fff" />
                    <path id="Path_142" data-name="Path 142" d="M506.866,327.323c-.662,0-1.2.91-1.2,2.027s.536,2.016,1.2,2.019H630.254c.662,0,1.2-.91,1.2-2.027s-.536-2.016-1.2-2.019Z" transform="translate(-382.228 -268.686)" fill="#e6e6e6" />
                    <path id="Path_143" data-name="Path 143" d="M506.866,354.712c-.662,0-1.2.91-1.2,2.027s.536,2.016,1.2,2.019h91.177c.662,0,1.2-.91,1.2-2.027s-.536-2.016-1.2-2.019Z" transform="translate(-382.228 -283.938)" fill="#e6e6e6" />
                    <path id="Path_142-2" data-name="Path 142" d="M506.866,381.482c-.662,0-1.2.91-1.2,2.027s.536,2.016,1.2,2.019H630.254c.662,0,1.2-.91,1.2-2.027s-.536-2.016-1.2-2.019Z" transform="translate(-382.228 -298.845)" fill="#e6e6e6" />
                    <path id="Path_143-2" data-name="Path 143" d="M506.866,408.871c-.662,0-1.2.91-1.2,2.027s.536,2.016,1.2,2.019h91.177c.662,0,1.2-.91,1.2-2.027s-.536-2.016-1.2-2.019Z" transform="translate(-382.228 -314.097)" fill="#e6e6e6" />
                    <path id="Path_142-3" data-name="Path 142" d="M290.792,428.323c-.662,0-1.2.91-1.2,2.027s.536,2.016,1.2,2.019H351.7c.662,0,1.2-.91,1.2-2.027s-.536-2.016-1.2-2.019Z" transform="translate(-261.905 -324.929)" fill="#e6e6e6" />
                    <path id="Path_143-3" data-name="Path 143" d="M290.792,455.712c-.662,0-1.2.91-1.2,2.027s.536,2.016,1.2,2.019h28.695c.662,0,1.2-.91,1.2-2.027s-.536-2.016-1.2-2.019Z" transform="translate(-261.905 -340.181)" fill="#e6e6e6" />
                    <path id="Path_620" data-name="Path 620" d="M353.308,291.782a24.687,24.687,0,1,0-42.661,23.451c.222.279.452.558.687.829.018.018.031.035.049.053a23.991,23.991,0,0,0,1.839,1.9c.168.155.346.315.523.465.337.3.682.589,1.041.864a24.278,24.278,0,0,0,2.189,1.538l.027.013c.492.306.993.589,1.5.86l.208.106c.479.248.966.483,1.467.7.1.044.213.089.315.133l.022.009a23.977,23.977,0,0,0,3.111,1.05,24.7,24.7,0,0,0,6.368.833c.784,0,1.56-.035,2.326-.111a24.6,24.6,0,0,0,5.158-1.041s.009,0,.013,0a24.2,24.2,0,0,0,3.1-1.227s.013,0,.018-.009c.226-.106.443-.222.665-.332l.106-.053c.177-.093.354-.186.532-.284.443-.244.877-.5,1.307-.775.12-.071.235-.146.354-.226.39-.257.775-.532,1.148-.811.067-.049.137-.1.2-.151a24.679,24.679,0,0,0,8.384-27.776Z" transform="translate(-270.654 -239.666)" fill="#fff" />
                    <circle id="Ellipse_188" data-name="Ellipse 188" cx="3.7" cy="3.7" r="3.7" transform="translate(63.787 63.006)" fill="#2f2e41" />
                    <path id="Path_969" data-name="Path 969" d="M378.1,336.719a3.7,3.7,0,0,1,3.257,5.456,3.7,3.7,0,1,0-6.145-4.064,3.691,3.691,0,0,1,2.889-1.391Z" transform="translate(-309.581 -273.611)" fill="#2f2e41" />
                    <circle id="Ellipse_189" data-name="Ellipse 189" cx="10.868" cy="10.868" r="10.868" transform="translate(49.705 44.961)" fill="#2f2e41" />
                    <path id="Path_970" data-name="Path 970" d="M340.37,300.536a10.867,10.867,0,0,1,15.062-1.14c-.089-.085-.177-.17-.269-.252a10.868,10.868,0,0,0-14.5,16.19l.062.055c.092.082.187.16.281.238a10.867,10.867,0,0,1-.632-15.092Z" transform="translate(-288.327 -251.45)" fill="#2f2e41" />
                    <circle id="Ellipse_190" data-name="Ellipse 190" cx="7" cy="7" r="7" transform="translate(53.481 52.606)" fill="#a0616a" />
                    <path id="Path_621" data-name="Path 621" d="M351.854,357.183v2.349c-.43.275-.864.532-1.307.775-.177.1-.354.191-.532.284l-.106.053c-.222.111-.439.226-.665.332,0,0-.013,0-.018.009a24.194,24.194,0,0,1-3.1,1.227s-.009,0-.013,0a24.6,24.6,0,0,1-5.158,1.041c-.767.075-1.542.111-2.326.111a24.7,24.7,0,0,1-6.368-.833,23.98,23.98,0,0,1-3.111-1.05l-.022-.009c-.1-.044-.213-.089-.315-.133-.5-.217-.988-.452-1.467-.7l-.208-.106c-.51-.27-1.01-.554-1.5-.86l-.027-.013a24.271,24.271,0,0,1-2.189-1.538c-.359-.275-.7-.563-1.041-.864-.177-.151-.354-.31-.523-.465a23.991,23.991,0,0,1-1.839-1.9,8.964,8.964,0,0,1,.337-1.36l0,0a2,2,0,0,1,.093-.222,1.766,1.766,0,0,1,.084-.186c.793-1.484,2.229-1.852,4.01-1.932,1.006-.044,2.118,0,3.293-.009.62,0,1.263-.027,1.905-.084,4.055-.368,3.319-2.761,3.5-4.05.182-1.25,1.307-.168,1.383-.1l0,0a14.3,14.3,0,0,0,7.356,1.289c.434-.053.869-.084,1.3-.1,1.143-.013.9.315.5.585a4.954,4.954,0,0,1-.6.337s-.182,1.108-.368,2.765c-.177,1.586,2.863,2.331,3.115,2.388a.027.027,0,0,0,.018,0,6.1,6.1,0,0,1,3.372.089h0A4,4,0,0,1,351.854,357.183Z" transform="translate(-278.847 -279.339)" fill="#cbcbcb" />
                    <path id="Path_622" data-name="Path 622" d="M334.709,365.028c0,.328-.009.643-.022.939,0,.177-.013.35-.022.514l-.022-.009c-.1-.044-.213-.089-.315-.133-.5-.217-.988-.452-1.467-.7l-.208-.106c-.51-.27-1.01-.554-1.5-.86.013-.155.036-.31.058-.465.049-.341.089-.687.115-1.028a14.262,14.262,0,0,0-1.263-6.984v0c-.089-.177-.142-.275-.142-.275a2.671,2.671,0,0,1,3.128,0,1.107,1.107,0,0,1,.306.266C334.461,357.49,334.762,361.886,334.709,365.028Z" transform="translate(-284.36 -284.335)" fill="#2f2e41" />
                    <path id="Path_623" data-name="Path 623" d="M381.749,368.513c-.018.5-.044,1.015-.075,1.542v0s-.013,0-.018.009a24.194,24.194,0,0,1-3.1,1.227s-.009,0-.013,0l-.049-1.245,0-.164-.235-6.062a1.017,1.017,0,0,1,1.015-1.064h2.247c.111,0,.191.226.244.634v0A38.4,38.4,0,0,1,381.749,368.513Z" transform="translate(-311.277 -288.423)" fill="#2f2e41" />
                    <path id="Path_975" data-name="Path 975" d="M350.082,309.764v7H351.7l2.063-2.21-.276,2.21h7.166l-.443-2.21.886,2.21h1.142v-7Z" transform="translate(-295.588 -258.908)" fill="#2f2e41" />
                    <ellipse id="Ellipse_191" data-name="Ellipse 191" cx="0.553" cy="1.013" rx="0.553" ry="1.013" transform="translate(52.837 57.12)" fill="#a0616a" />
                    <ellipse id="Ellipse_192" data-name="Ellipse 192" cx="0.553" cy="1.013" rx="0.553" ry="1.013" transform="translate(67.021 57.12)" fill="#a0616a" />
                    <path id="Path_624" data-name="Path 624" d="M353.308,291.782a24.687,24.687,0,1,0-42.661,23.451c.222.279.452.558.687.829.018.018.031.035.049.053a23.991,23.991,0,0,0,1.839,1.9c.168.155.346.315.523.465.337.3.682.589,1.041.864a24.278,24.278,0,0,0,2.189,1.538l.027.013c.492.306.993.589,1.5.86l.208.106c.479.248.966.483,1.467.7.1.044.213.089.315.133l.022.009a23.977,23.977,0,0,0,3.111,1.05,24.7,24.7,0,0,0,6.368.833c.784,0,1.56-.035,2.326-.111a24.6,24.6,0,0,0,5.158-1.041s.009,0,.013,0a24.2,24.2,0,0,0,3.1-1.227s.013,0,.018-.009c.226-.106.443-.222.665-.332l.106-.053c.177-.093.354-.186.532-.284.443-.244.877-.5,1.307-.775.12-.071.235-.146.354-.226.39-.257.775-.532,1.148-.811.067-.049.137-.1.2-.151a24.679,24.679,0,0,0,8.384-27.776Zm-9.2,26.7c-.146.115-.3.226-.447.328s-.293.217-.443.315c-.128.093-.257.182-.39.266-.111.075-.222.146-.332.217q-.551.346-1.116.665l-.12.066c-.168.093-.337.182-.505.27-.022.013-.049.022-.071.035l-.027.013c-.213.111-.43.217-.647.319a21.717,21.717,0,0,1-2.583,1.046c-.115.04-.23.08-.346.115a23.244,23.244,0,0,1-4.883.993c-.727.066-1.462.1-2.2.1a23.023,23.023,0,0,1-6.022-.789,21.891,21.891,0,0,1-2.956-1c-.1-.04-.2-.084-.3-.124-.053-.022-.1-.044-.155-.071-.417-.182-.828-.381-1.232-.589l-.195-.1c-.492-.257-.975-.532-1.445-.824-.173-.106-.346-.217-.514-.332-.532-.35-1.05-.727-1.556-1.121-.341-.257-.669-.532-.988-.815-.168-.142-.332-.3-.492-.443a22.549,22.549,0,0,1-1.781-1.843c-.186-.213-.368-.425-.541-.647a1.681,1.681,0,0,1-.111-.137,23.335,23.335,0,1,1,32.4,4.09Z" transform="translate(-270.654 -239.666)" fill="#cbcbcb" />
                    <path id="Path_625" data-name="Path 625" d="M746.13,383.894a5.155,5.155,0,0,1,.616.528l23.82-4.7,1.914-5.579,8.8,1.788-2.428,10.206a3.917,3.917,0,0,1-3.614,3.005l-27.588,1.382a5.139,5.139,0,1,1-1.518-6.631Z" transform="translate(-511.602 -294.759)" fill="#ffb7b7" />
                    <path id="Path_626" data-name="Path 626" d="M809.827,362.644a2.2,2.2,0,0,1-.159-1.828l3.718-10.413a6.118,6.118,0,1,1,12.01,2.343l-.538,11.08a2.2,2.2,0,0,1-2.629,2.054l-10.914-2.163a2.2,2.2,0,0,1-1.488-1.074Z" transform="translate(-551.443 -278.783)" fill="#6c63ff" />
                    <path id="Path_627" data-name="Path 627" d="M556.612,460.071h-7l-3.332-27.012h10.336Z" transform="translate(-304.201 -241.154)" fill="#ffb7b7" />
                    <path id="Path_628" data-name="Path 628" d="M772.361,692.931H749.781v-.286a8.789,8.789,0,0,1,8.788-8.789h13.793Z" transform="translate(-518.165 -467.225)" fill="#2f2e41" />
                    <path id="Path_629" data-name="Path 629" d="M694.422,446.015l-6.486,2.641-13.272-23.761,9.573-3.9Z" transform="translate(-375.696 -234.438)" fill="#ffb7b7" />
                    <path id="Path_630" data-name="Path 630" d="M928.634,674.559l-20.914,8.514-.108-.265a8.79,8.79,0,0,1,4.826-11.454h0l12.773-5.2Z" transform="translate(-605.693 -457.368)" fill="#2f2e41" />
                    <path id="Path_631" data-name="Path 631" d="M783.185,376.071l-3.222-17.048h0a17.3,17.3,0,0,1,2.663-14.813l11.78-15.75,11.251.331h0a64.239,64.239,0,0,1,2.791,40.665l-2.068,7.956c9.275,8.366,15.493,31.821,21.154,58.032l15.517,34.317-26.7,8.1-17.3-50.328-15.45,50.7-22.624-.868,17.208-78.427Z" transform="translate(-524.403 -269.319)" fill="#2f2e41" />
                    <circle id="Ellipse_79" data-name="Ellipse 79" cx="10.976" cy="10.976" r="10.976" transform="translate(265.732 34.007)" fill="#ffb7b7" />
                    <path id="Path_632" data-name="Path 632" d="M879.782,298.537c-2.039-3.983-2.735-8.506-4.3-12.7s-4.421-8.348-8.744-9.5c-2.452-.655-5.058-.255-7.577-.567-2.215-.274-4.6-1.4-5.447-3.372a13.891,13.891,0,0,0-.715-5.763,17.287,17.287,0,0,0-9.74-9.865,15.93,15.93,0,0,0-11.321-.616,11.174,11.174,0,0,0-7.357,8.261c-.655,3.81,1.445,8.065,5.067,9.418,2.6.972,5.949.648,7.646,2.844,1.72,2.225.487,5.467-1.075,7.805l.107.109c5.1.06,10.617-.926,14.132-4.626a11.2,11.2,0,0,0,1.05-1.292c.108.173.22.341.342.5,1.77,2.35,4.54,3.683,7.179,4.982s5.377,2.756,6.962,5.234c2.865,4.478.984,10.509,2.623,15.566a11.5,11.5,0,0,0,21.626.7C885.774,306.031,881.821,302.52,879.782,298.537Z" transform="translate(-559.75 -228.625)" fill="#2f2e41" />
                    <path id="Path_633" data-name="Path 633" d="M709.273,358.955l.464-11.278,15.929-31.671a4.594,4.594,0,0,1,2.084-2.072,4.649,4.649,0,0,1,6.489,2.7,4.808,4.808,0,0,1,.2.9,4.6,4.6,0,0,1-.462,2.649L718.029,351.9Z" transform="translate(-495.608 -260.958)" fill="#fbb633" />
                    <path id="Path_634" data-name="Path 634" d="M732.063,313.947l-17.291,34.379-1.318-.663,16.724-33.25A4.407,4.407,0,0,1,732.063,313.947Z" transform="translate(-497.936 -261.237)" fill="#3f3d56" />
                    <path id="Path_635" data-name="Path 635" d="M743.017,320.355l-16.632,33.068-1.318-.663,17.255-34.308a4.434,4.434,0,0,1,.5,1.042A4.582,4.582,0,0,1,743.017,320.355Z" transform="translate(-504.403 -263.746)" fill="#3f3d56" />
                    <path id="Path_636" data-name="Path 636" d="M738.213,315.2,720.73,349.957l-1.318-.663,17.5-34.8a4.288,4.288,0,0,1,.544.229,4.518,4.518,0,0,1,.752.478Z" transform="translate(-501.254 -261.541)" fill="#3f3d56" />
                    <path id="Path_637" data-name="Path 637" d="M711.575,408.955l-1.759,1.417.147-3.735A3.5,3.5,0,0,1,711.575,408.955Z" transform="translate(-495.91 -312.853)" fill="#3f3d56" />
                    <path id="Path_638" data-name="Path 638" d="M741.972,326.763a5.143,5.143,0,0,1,.117.8L763.119,339.7l5.112-2.943,5.449,7.134-8.543,6.089a3.917,3.917,0,0,1-4.7-.115l-21.686-17.108a5.139,5.139,0,1,1,3.219-5.993Z" transform="translate(-508.169 -266.166)" fill="#ffb7b7" />
                    <path id="Path_639" data-name="Path 639" d="M803.036,347.943a2.2,2.2,0,0,1,1.083-1.481l9.651-5.4a6.118,6.118,0,1,1,7.5,9.666l-7.695,7.99a2.2,2.2,0,0,1-3.331-.182l-6.8-8.809A2.2,2.2,0,0,1,803.036,347.943Z" transform="translate(-547.796 -275.623)" fill="#fbb633" />
                    <path id="Path_640" data-name="Path 640" d="M757.014,703.886H588.179a.443.443,0,1,1,0-.886H757.014a.443.443,0,1,1,0,.886Z" transform="translate(-417.929 -477.886)" fill="#c2c2c2" />
                </svg>
            )
        }
    }
    const color = "#2EB7D7";
    return (
        <FormProvider {...methods}>
            <CustomRoot>
                <Stack direction={'row'} className=" bg-pi-blue-2 items-end justify-around w-full px-16 h-288  max-600px:h-128 max-600px:justify-start"  >
                    <div className="mb-32">
                        <Typography variant="h4" className=" font-extrabold">Manage Your Profile</Typography>
                        <Typography variant="h5">Customize your profile.</Typography>
                    </div>
                    <div className=" max-600px:hidden block">
                        <ManageProfileBg />
                    </div>
                </Stack>
                <Stack direction={'column'} gap={{ lg: 4, md: 2, sm: 2, xs: 2 }} className=" px-0 max1200px:py-40 py-20 m-auto max1200px:w-pi-655px-vw sm:w-4/5 w-full">
                    <Card className="p-24 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                        <Stack gap={1.5}>
                            <FormControl
                                className="flex w-full  ml-0"
                                variant="outlined"
                            >
                                <AgentProfileImage />
                            </FormControl>
                            <Divider />
                            <FormControl
                                className="flex w-full  ml-0"
                                variant="outlined"
                            >
                                <AgentProfileBannerImage />
                            </FormControl>
                            <Divider />
                            <Typography className=" text-18 font-bold mt-16">
                                User Information
                            </Typography>
                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">First Name <span className="text-red-500">*</span></Typography>}
                                            placeholder="Enter your first name"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            {...field}
                                            error={!!errors.firstName}
                                            required
                                            helperText={errors?.firstName?.message}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56
                                                }
                                            }}
                                            id="firstName"
                                            fullWidth
                                        />
                                    </div>
                                )}
                            />
                            <Controller
                                name="middleName"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">Middle Name </Typography>}
                                            placeholder="Enter your middle name"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            {...field}
                                            className=""
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56
                                                }
                                            }}
                                            id="middleName"
                                            fullWidth
                                        />
                                    </div>
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">Last Name <span className="text-red-500">*</span></Typography>}
                                            placeholder="Enter your last name"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            {...field}
                                            className=""
                                            error={!!errors.lastName}
                                            required
                                            helperText={errors?.lastName?.message}
                                            id="lastName"
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56
                                                }
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                )}
                            />
                            <FormControl
                                className="flex w-full max-w-fit"
                                variant="outlined">
                                <Controller
                                    control={control}
                                    name="birthdate"
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                renderInput={(field) => (
                                                    <TextFieldStyled
                                                        {...field}
                                                        label={<Typography className="text-15" color="text.primary">Birthday</Typography>}
                                                        variant="filled"
                                                        InputLabelProps={{
                                                            sx: {
                                                                color: "#48525C",
                                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                [`&.${inputLabelClasses.shrink}`]: {
                                                                    fontSize: '14px',
                                                                    color: !!errors.email ? "red" : "#141F2C"
                                                                }
                                                            }
                                                        }}
                                                        sx={{
                                                            svg: { color },
                                                            "& .MuiInputBase-root": {
                                                                height: 56
                                                            }
                                                        }}
                                                        placeholder="MM/DD/YYYY" />
                                                )}
                                                onChangeRaw={(e) => {
                                                    setFieldTouched(field.name, true, true);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </FormControl>
                            <Controller
                                render={({ field }) => (
                                    <div className="">
                                        <Typography id="demo-row-radio-buttons-group-label" className="text-15" color="text.primary">
                                            Gender <span className="text-red-500">*</span>
                                        </Typography>
                                        <RadioGroup {...field} row>
                                            <FormControlLabel
                                                value="Male"
                                                className="text-15"
                                                control={<Radio sx={{
                                                    '&.Mui-checked': {
                                                        color: color,
                                                    },
                                                }} />}
                                                label="Male"
                                                sx={{
                                                    "& .MuiTypography-root": {
                                                        fontSize: 15
                                                    }
                                                }}
                                            />
                                            <FormControlLabel
                                                value="Female"
                                                control={<Radio sx={{
                                                    '&.Mui-checked': {
                                                        color: color,
                                                    },
                                                }} />}
                                                label="Female"
                                                sx={{
                                                    "& .MuiTypography-root": {
                                                        fontSize: 15
                                                    }
                                                }}
                                            />
                                        </RadioGroup>
                                    </div>
                                )}
                                name="gender"
                                control={control}
                            />
                            <Controller
                                name="about"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">About yourself</Typography>}
                                            placeholder="Short description about yourself"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 'auto !important'
                                                }
                                            }}
                                            {...field}
                                            className=" "
                                            error={!!errors.about}
                                            helperText={errors?.about?.message}
                                            id="about"
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            minRows={3}
                                        />
                                    </div>

                                )}
                            />
                            <Typography className=" text-18 font-bold mt-16">
                                Address
                            </Typography>
                            <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                    <FormControlStyled fullWidth variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.email ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        sx={{
                                            svg: { color },
                                            "& .MuiInputBase-root": {
                                                height: 56
                                            }
                                        }}
                                        placeholder="Choose your country"

                                    >
                                        <InputLabel id="country-label">Country <span className="text-red-500">*</span></InputLabel>
                                        <Select
                                            labelId="country-label"
                                            label="Country"
                                            {...field}
                                            id="type-select"
                                            required
                                            error={!!errors.country}
                                        >
                                            {CountryRegionData.map((type) => (
                                                <MenuItem value={type[0]} key={type[1]} onClick={() => {
                                                    setValue("country", type[0]);
                                                }}>
                                                    {type[0] + ' (' + type[1] + ')'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControlStyled>
                                )}
                            />

                            <FormHelperText className="-mt-8" error={!!errors.type}>{errors?.type?.message}</FormHelperText>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <FormControlStyled fullWidth variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.email ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        sx={{
                                            svg: { color },
                                            "& .MuiInputBase-root": {
                                                height: 56
                                            }
                                        }}
                                        placeholder="Choose your country">
                                        <InputLabel id="state-label">State <span className="text-red-500">*</span></InputLabel>
                                        <Select
                                            labelId="state-label"
                                            label="Country"
                                            {...field}
                                            id="type-select"
                                            required
                                            error={!!errors.state}
                                        >
                                            {states.map((type) => (
                                                <MenuItem value={type.split('~')[0]} key={type.split('~')[0]} onClick={() => {
                                                    setValue("state", type.split('~')[0]);
                                                }}>
                                                    {type.split('~')[0]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControlStyled>
                                )}
                            />
                            <FormHelperText className="-mt-8" error={!!errors.state}>{errors?.state?.message}</FormHelperText>
                            <Controller
                                name="city"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">City </Typography>}
                                            placeholder="Enter your City"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56
                                                }
                                            }}
                                            {...field}
                                            className=""
                                            error={!!errors.city}
                                            required
                                            helperText={errors?.city?.message}
                                            id="city"
                                            fullWidth
                                        />
                                    </div>
                                )}
                            />

                            <Button onClick={handleupdateAgent} variant="contained" color="primary"
                                className=" mt-16 px-24 py-8 text-16 rounded-full font-bold w-full bg-pi-yellow-green">
                                UPDATE
                            </Button>

                        </Stack>
                    </Card>
                </Stack>
            </CustomRoot>
        </FormProvider >
    );
}

export default withReducer('agentApp', reducer)(AgentProfileEdit);