import React, { useState } from "react";
import AboutContent from "app/main/apps/public/about/content/AboutContent";

function PublicAbout(props) {
    return (
        <CustomRoot>
            <AboutContent />
        </CustomRoot>
    );
}

export default PublicAbout;
