import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { isJoined } from "./store/ServiceSlice";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon, } from "@mui/material";
import LazyLoad from 'react-lazy-load';
import AgentJwtService from 'app/services/agent/AgentJwtService';

//Router
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";

function JoinButtonSvs({ agent_id, mission_id, module, return_path = false }) {
    const token = AgentJwtService.getDecodedAccessToken();
    const isValidToken = token && (Array.isArray(token) ? token.length > 0 : Object.keys(token).length > 0);
    const dispatch = useDispatch();

    const [isJoin, setIsJoin] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    console.log('JoinButtonSvs', agent_id);

    useEffect(() => {
        dispatch(isJoined({ agentId: agent_id, missionId: mission_id, agentId: token.id })).then((action) => {
            const data = action.payload;
            setIsJoin(data);
        })
    }, [dispatch]);

    function checkLogin() {
        if (!isValidToken) {
            window.location.href = "/agentlogin?return=" + window.location.pathname;
        }
    }
    class JoinPinned extends React.Component {
        render() {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                    <path id="Path_map-marker-multiple-outline" data-name="Path / map-marker-multiple-outline" d="M11.5,9a2.5,2.5,0,0,1,4.87-.81A2.746,2.746,0,0,1,16.5,9a2.5,2.5,0,0,1-4.86.84A2.6,2.6,0,0,1,11.5,9M5,9c0,4.5,5.08,10.66,6,11.81L10,22S3,14.25,3,9A7.005,7.005,0,0,1,8,2.29,8.982,8.982,0,0,0,5,9m9-7a7,7,0,0,1,7,7c0,5.25-7,13-7,13S7,14.25,7,9a7,7,0,0,1,7-7m0,2A5,5,0,0,0,9,9c0,1,0,3,5,9.71C19,12,19,10,19,9A5,5,0,0,0,14,4Z" transform="translate(-3 -2)" fill="#fff" />
                </svg>

            )
        }
    }
    
    if (isJoin == null) {
        return (<></>);
    }
    return (
        <>
            {
                (() => {
                    switch (module) {
                        case "at-1":
                            return <div>
                                {!isJoin && (
                                    <div className="map-button-container ">
                                        <Link
                                            to={`/public/mission-details/${mission_id}?join=true&return=${return_path}`}
                                        >
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="secondary"
                                                className="px-24 py-8 text-16 font-bold max-w-max rounded-md bg-pi-blue-1  hover:bg-pi-blue-1 text-white"
                                            >
                                                Join Mission
                                            </Button>
                                        </Link>
                                    </div>
                                )}
                            </div >;
                        case "at-2":
                            return <>
                                {!isJoin ? (
                                    <Link
                                        to={`/public/mission-details/${mission_id}?join=true&return=${return_path}`}
                                    >
                                        <Button aria-label="Join Mission" variant="contained" className="px-8 py-2 rounded-md hover:bg-pi-blue-1 bg-pi-blue-1 text-white font-bold">
                                            Join mission
                                        </Button>
                                    </Link>
                                ) :
                                    <Button aria-label="Joined Mission" variant="contained" className="px-8 py-2 rounded-md !bg-pi-yellow-orange-1 !text-pi-yellow-orange font-bold" disabled>
                                        Joined
                                    </Button>
                                }
                            </>;
                        case "at-3":
                            return <>
                                {!isJoin && isValidToken ? (
                                    <Link
                                        to={`/public/mission-details/${mission_id}?agent=${agent_id}&join=true&return=${return_path}`}
                                    >
                                        <Button className=" rounded-md bg-pi-blue-1 text-white font-semibold hover:bg-pi-blue-1 mt-16 text-16 px-14" startIcon={<Icon ><JoinPinned /></Icon>}>Join this mission now</Button>
                                    </Link>
                                ) : (
                                    <Button className=" rounded-md bg-pi-blue-1 text-white font-semibold hover:bg-pi-blue-1 mt-16 text-16 px-14" onClick={checkLogin} startIcon={<Icon ><JoinPinned /></Icon>}>Join this mission now</Button>
                                )}
                            </>;
                        case "at-4":
                            return <>
                                {!isJoin && isValidToken ? (
                                    <Link
                                        to={`/public/mission-details/${mission_id}?agent=${agent_id}&join=true&return=${return_path}`}
                                    >
                                        <Button className=" rounded-md bg-pi-blue-1 text-white font-semibold hover:bg-pi-blue-1 mt-16 text-16 px-14" startIcon={<Icon ><JoinPinned /></Icon>}>Join this mission now</Button>
                                    </Link>
                                ) : (
                                    <>
                                       
                                    </>
                                )}
                            </>;
                        default:
                            return <div></div>;
                    }
                })()
            }
        </>
    );
}

export default JoinButtonSvs;