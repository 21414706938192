
import React, { useState, useRef, useEffect } from "react";

import FriendsActivityContentV2 from "./content/FriendsActivityContentV2";
import CustomRoot from "../custom-component/CustomRoot";
function FriendsActivity(props) {
    localStorage.setItem('active_menu', 'Friends Activity');
    return (
        <CustomRoot>
            <FriendsActivityContentV2 />
        </CustomRoot>
    );
}

export default FriendsActivity;
