import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import _ from '@lodash';
import { navbarToggleMobile, navbarToggle } from '../../store/fuse/navbarSlice';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

function NavbarToggleButton({ className, children }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
  const settings = useSelector(({ fuse }) => fuse.settings.current);
  const { config } = settings.layout;
  const type = useSelector(({ fuse }) => fuse.settings.current.type); // ✅ Added missing `type`

  const handleClick = useCallback(() => {
    if (mdDown) {
      dispatch(navbarToggleMobile());
    } else {
      const newSettings = _.cloneDeep(settings);
      newSettings.layout.config.navbar.style = config.navbar.style === 'style-1' ? 'style-2' : 'style-1';
      dispatch(setDefaultSettings(newSettings));
    }
  }, [mdDown, dispatch, settings]);

  return (
    <>
      {type !== 'public' && config.navbar.display && config.navbar.position === 'left' ? (
        <IconButton
          className={className}
          color="inherit"
          size="small"
          edge={false}
          onClick={(ev) => {
            if (mdDown) {
              dispatch(navbarToggleMobile());
            } else if (config.navbar.style === 'style-2') {
              dispatch(
                setDefaultSettings(
                  _.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded)
                )
              );
            } else {
              dispatch(navbarToggle());
            }
          }}
        >
          {children}
        </IconButton>
      ) : (
        <IconButton className={className} color="inherit" size="small" onClick={handleClick}>
          {children}
        </IconButton>
      )}
    </>
  );
}

NavbarToggleButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

NavbarToggleButton.defaultProps = {
  children: (
    <Icon fontSize="inherit" className="text-20 font-medium">
      menu_open
    </Icon>
  ),
};

export default NavbarToggleButton;
