import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import React, { useState, useRef, useEffect, useCallback } from "react";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { GlobalStyles, height } from "@mui/system";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Stack, TextareaAutosize, Typography } from "@mui/material";
import { PinIcon, SeenIcon, CommentIcon, PinnedIcon, PinnedIconBlue } from "../../../custom-icon/MissionCounterIcons"
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import PhotoGridView from "app/services/PhotoGridView";
import SharerSvs from "app/services/SharerSvs";
import Commentsv2 from "app/services/comments/Commentsv2";
import { updateViews } from "../blog/store/PublicBlogSlice";
import { getCommunityId } from "./store/CommunityManageSlice";
const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full`": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .FusePageSimple-toolbar": {
        height: "76px",
    },
}));
function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}

function PublicCommunityDetails(props) {
    const { id } = useParams();
    // const location = useLocation();
    // const item = location.state?.item; // di pwedeng gamitin ang state since shareable ito
    const [community, setcommunity] = useState([]);
    const dispatch = useDispatch();


    const commentsSectionRef = useRef(null);

    const scrollToComments = () => {
        commentsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(rData =>
                dispatch(updateViews({ ipAddress: rData.ip, module: "community", moduleId: id })).then((action) => {
                }))
            .catch(error => console.log(error))

        dispatch(getCommunityId(id)).then((communityAction) => {
            setcommunity(communityAction.payload);
        });
    }, [])
    const reloadActivities = useCallback(() => {
        dispatch(getCommunityId(id)).then((communityAction) => {
            setcommunity(communityAction.payload);
        });
    }, [community]);
    return (
        <>
            <Root
                content={
                    <Stack gap={1.5} className="w-full  max1200px:w-lg md:w-10/12 sm:w-full p-16 mt-100 m-auto mb-32 ">
                        <Card className="p-16 pb-8 rounded-lg mt-40 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                            <CardHeader
                                className="p-0"
                                sx={{
                                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                    // "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                                }}
                                avatar={
                                    <>
                                        <Avatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} src={community.agent?.profileUrl} />
                                    </>
                                }
                                title={
                                    <Typography className=" font-extrabold text-18">
                                        {community.agent?.firstName} {community.agent?.lastName}
                                    </Typography>
                                }
                                subheader={
                                    <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                        {"@" + community.agent?.username} · Posted {community.timeAgo}
                                    </Typography>
                                }
                                action={
                                    ""
                                }
                            />
                            <CardContent className="p-0">
                                <Box className="flex items-end justify-start relative">
                                    <Typography
                                        className="text-16 my-4 "
                                    >
                                        {community.title}
                                    </Typography>
                                </Box>
                                <PhotoGridView fileId={community.fileId} moduleType={'community'} />
                            </CardContent>

                            <CardActions className="flex-col pt-2 pb-0  px-0 justify-start items-start">
                                <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                    <Typography className=" gap-1 flex items-center ">
                                        <SeenIcon />
                                        <span className="mt-1">{community.views}</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center  cursor-pointer" onClick={scrollToComments}>
                                        <CommentIcon />
                                        <span className="mt-1">{community.commentsCount} Comment{community.commentsCount !== 1 ? 's' : ''}</span>
                                    </Typography>

                                    <Box className="flex-1 text-right">
                                        <SharerSvs link={`/public/community-details/${id}`} title={community.title} description={""} />
                                    </Box>
                                </Stack>
                            </CardActions>
                        </Card>

                        <Card className="p-16 pb-8 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                            <Typography component="div" variant="h5" className="font-bold">
                                {community.commentsCount} Comment{community.commentsCount !== 1 ? 's' : ''}
                            </Typography>
                            <Divider className=" -mx-16 mt-16" />
                            <div ref={commentsSectionRef}>
                                <Commentsv2 reloadActivities={reloadActivities} moduleType="community" moduleId={id} />
                            </div>
                        </Card>

                    </Stack>
                }

            />
        </>
    );
}

export default PublicCommunityDetails;