import { Badge, Box, Card, CardContent, CardHeader, IconButton, Stack, Typography } from '@mui/material';
import AttachmentSvs from 'app/services/AttachmentSvs';
import DateTimeDisplay from 'app/services/DateTimeDisplay';
import PhotoGridView from 'app/services/PhotoGridView';
import TextLimit from 'app/services/TextLimit';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import Commentsv2 from "app/services/comments/Commentsv2";
import { PinnedIconBlue } from 'app/main/custom-icon/MissionCounterIcons';
import LikeButton from '../../../friends-activity/components/LikeButton';
import { useDispatch } from "react-redux";
import { getMarkersByMissionId } from '../../store/missionDetailsSlice';
// import { getMarkersByMissionId } from "app/main/apps/public/mission/store/missionDetailsSlice";

const JoinerList = ({ items, onItemCenter, mission_id }) => {
    const [isExpandedrep, setIsExpandedrep] = useState([]);
    const observerRef = useRef();
    const itemRefs = useRef({});
    const dispatch = useDispatch();


    const [data, setData] = useState(items);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1, // Trigger when 50% of the element is in view n
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Call the function when item reaches the center
                    onItemCenter(itemRefs.current[entry.target.dataset.itemId].id);
                }
            });
        }, options);

        observerRef.current = observer;

        // return () => {
        //     // Cleanup observer on component unmount
        //     observer.disconnect();
        // };
    }, [onItemCenter]);

    useEffect(() => {
        // Observe each item in the list
        data.forEach((item) => {
            console.log("item", item)
            itemRefs.current[item.id] = item.ref;
            observerRef.current.observe(item.ref);
        });

        return () => {
            // Cleanup observer on component unmount
            data.forEach((item) => {
                observerRef.current.unobserve(itemRefs.current[item.id]);
            });
        };
    }, [data]);

    const toggleExpand = (index) => {
        setIsExpandedrep((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    const dataReload = useCallback(async () => {
        try {

            const markersAction = await dispatch(getMarkersByMissionId({ id: mission_id, type: 'markers' }));

            const newData = markersAction.payload;
            console.log("MissionDetailContent", newData);

            if (Array.isArray(newData)) {
                setData(prevMissions =>
                    prevMissions.map(mission => {
                        const updatedMission = newData.find(item => item.id === mission.id);
                        if (updatedMission) {
                            return {
                                ...mission,
                                likers: updatedMission.likers
                            };
                        }
                        return mission;
                    })
                );
            } else {
                console.error('Unexpected data structure received:', newData);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [dispatch]);

    return (
        <div className='p-16 pt-0'>
            {data && data.map((item, index) => (
                <div id={item.id} key={index} ref={(el) => (item.ref = el)} data-item-id={item.id}>


                    <Card className="p-16 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={index}>
                        <CardHeader
                            className="p-0"
                            sx={{
                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                            }}
                            avatar={
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                    badgeContent={
                                        <span
                                        >
                                            <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                                        </span>
                                    }
                                >
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                        <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={{ name: item.agent?.name }} />
                                    </Link>

                                </Badge>
                            }
                            title={
                                <Typography className=" font-extrabold text-16">
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                        {item.agent?.flName}
                                    </Link>
                                </Typography>
                            }
                            subheader={
                                <Typography className=" line-clamp-1 font-500 text-15 text-pi-grey">
                                    Joined <DateTimeDisplay timeago={item.timeAgo} />
                                </Typography>
                            }
                            action={
                                <IconButton aria-label="settings" className="hidden" edge={false}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        />
                        <CardContent className="p-0">
                            <Typography
                                component="div"
                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                            >
                                {item.title}
                            </Typography>
                            <Box className="flex items-end mb-6 relative">
                                <div className="text-16 mb-3">
                                    {item.description && (
                                        <TextLimit text={item.description} limit={200} />
                                    )}
                                </div>
                            </Box>
                        </CardContent>
                        <PhotoGridView fileId={item.filesId} />
                        <Stack className="mt-6 -ml-5" direction={'row'} gap={3} alignItems={'center'}>
                            <Stack direction={'row'} alignItems={'center'}>
                                <LikeButton
                                    missionId={item.missionId}
                                    userId={item.agent?.userId}
                                    flName={item.agent?.flName}
                                    initialAction={item.action}
                                    reloadActivities={() => dataReload()}
                                    likers={item.likers}
                                    link={'/public/mission-details/' + item.id}
                                />
                            </Stack>
                            <Button
                                onClick={() => toggleExpand(index)}
                                size="small" variant="text" className="h-fit text-14 text-black">
                                {item.countOnCommentsById + ' ' + 'Replies'}
                                <Icon>{isExpandedrep.includes(index) ? 'expand_less' : 'expand_more'}</Icon>
                                {/* <Icon>expand_less</Icon> kapag naghide */}
                            </Button>
                        </Stack>
                        {isExpandedrep.includes(index) && (
                            <Commentsv2 moduleType="mission-joiners" mainMID={item.missionId} moduleId={item.id} reloadActivities={() => dataReload()} />
                        )}
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default JoinerList;