import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeCounters } from "../home/store/homeSlice";
import HomeContent from "./content/HomeContent";
import AgentJwtService from "app/services/agent/AgentJwtService";
import FooterLayout1 from "app/fuse-layouts/layout1/components/FooterLayout1";
import CustomRoot from "../custom-component/CustomRoot";

const VideoIframe = styled('div')(({ theme, config }) => ({
    /* Corrected styles for the YouTube video */
    position: "relative",
    width: "100%",
    maxWidth: "29vw",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
    aspectRatio: "16 / 9",
    margin: "auto",

    "& iframe": {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
}));

const ResponsiveYouTubeEmbed = () => {
    return (
        <VideoIframe>
            <iframe
                src="https://www.youtube.com/embed/GYThhOoYuos?autoplay=1&controls=0&modestbranding=1&rel=0&playsinline=1&fs=1&iv_load_policy=3"
                title="Introducing Planet Impossible - An Experiment In Collective Action"
                frameBorder="0"
                allow="autoplay; encrypted-media; picture-in-picture; fullscreen"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
        </VideoIframe>
    );
};


function PublicHome(props) {
    const token = AgentJwtService.getDecodedAccessToken();
    localStorage.setItem('active_menu', 'Home');
    const dispatch = useDispatch();
    const [dataAgents, setAgents] = useState('');
    useEffect(() => {
        dispatch(homeCounters()).then((action) => {
            setAgents(action.payload)
        });
    }, [dispatch])
    return (
        <CustomRoot>
            {token == "" ? (
                <div style={{ width: "100%", background: "#DAEFF5" }}>
                    <div className="flex gap-5 flex-1 smMax:flex-col sm:mb-16">
                        <Box className="relative !pr-0 sm:px-36  min-w-fit py-48px-vw px-48px-vw flex-1">
                            <Typography variant="h3" className="smMax:text-18  md:mt-0 mt-12px-vw font-extrabold text-60px-vw max1200px:text-42px-vw text-pi-dark-blue-text">New to Planet Impossible?</Typography>
                            <Typography className="smMax:text-14 text-28px-vw max1200px:text-24px-vw mt-8px-vw text-pi-dark-blue-text  smMax:contents">Join the Planet Impossible Network. Create Your Own Mission.
                                <span className="smMax:text-14 sm:block text-28px-vw max1200px:text-24px-vw mt-8px-vw text-pi-dark-blue-text ">We have <b className=" text-pi-red">{dataAgents.agents} Agents Joined</b>, <b className="text-pi-red">{dataAgents.missions}  Created Missions</b>, and <b className="text-pi-red">{dataAgents.pinned} Pins Dropped</b>.</span>
                                <span className="smMax:text-14 sm:block text-28px-vw max1200px:text-24px-vw mt-8px-vw text-pi-dark-blue-text ">Together, Let's Reach Impossible!</span></Typography>
                            <div className="w-fit">
                                <Link className="w-fit" style={{ textDecoration: 'none' }} to="/agentlogin">
                                    <Button
                                        variant="contained"
                                        className="block smMax:text-12 tracking-wider py-12px-vw px-40px-vw uppercase mt-30px-vw bg-pi-yellow-green text-28px-vw max1200px:text-18px-vw font-extrabold hover:bg-pi-hover-yellow text-pi-dark-blue-text min-w-fit rounded-md">
                                        Accept Mission
                                    </Button>
                                </Link>
                            </div>

                        </Box>
                        <div className="flex-1 max-w-[750px] content-center p-24 smMax:pt-0 smMax:p-16">
                            {/* <div style={{ height: "300px" }}>
                                                        <img width="600px" src="assets/images/pi-images/pi-bg-pop.png"></img>
                                                    </div> */}
                            {/* <div className=" absolute" style={{ top: "177px", right: "267px" }}> */}

                            <ResponsiveYouTubeEmbed />

                            {/* </div> */}
                        </div>
                    </div>
                </div >
            ) : <div className="pt-24" />}
            <HomeContent module={"home"} type={"featured"} initial={8} />
            <HomeContent module={"home"} type={"recent"} initial={8} />
            <HomeContent module={"home"} type={"popular"} initial={8} />

        </CustomRoot>
    );
}

export default PublicHome;
