import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, ListSubheader, Box, Button, Icon } from '@mui/material';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { agentById, getCounters } from '../navbar/store/navbarSlice';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function UserNavbarHeader(props) {
  const token = AgentJwtService.getDecodedAccessToken();
  const dispatch = useDispatch();
  const [data, setData] = useState('');
  const [userData, setUserData] = useState('');

  useEffect(() => {
    dispatch(getCounters(token.id)).then((action) => {
      setData(action.payload)
    });

  }, [dispatch])

  useEffect(() => {
    dispatch(agentById(token.id)).then((action) => {
      setUserData(action.payload)
      console.log('user data', action.payload)
    });

  }, [dispatch])

  const user = useSelector(({ auth }) => auth.user);
  return (
    <>
      {Object.keys(token).length > 0 ?
        <StyledAppBar
          position="static"
          color="primary"
          className=" bg-transparent user relative flex flex-col items-center justify-center mb-10 z-0 shadow-0 ">
          <div className=" pt-24 w-full">
            <div className="flex items-center">
              <Stack direction={'column'} alignItems={'center'} className="mt-10 w-full">
                <Link style={{ textDecoration: 'none' }} to={token.firstName ? `/agentprofile` : '/agentprofile/new-user?isNew=true'}>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    {userData?.profileUrl ? (
                      <Avatar sx={{ width: 86, height: 86 }} className=" border-2 border-pi-yellow-orange" alt="user photo" src={userData?.profileUrl} />
                    ) : (
                      <Avatar sx={{ width: 86, height: 86 }} className=" border-2 border-pi-yellow-orange"></Avatar>
                    )}
                    <Typography className="text-center text-20 font-extrabold mt-8">{userData?.firstName} {userData?.lastName}</Typography>
                    <Typography variant="body1" className=" text-center text-pi-grey -mt-3 text-16">@{userData?.username}</Typography>

                  </Stack>
                </Link>
                <Stack className="my-24 px-16 w-full" direction={'row'} useflexgap="true" gridTemplateRows={.5} justifyContent={'space-between'}>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <Typography className=" text-18 font-extrabold">{data && data.followers ? data.followers : 0}</Typography>
                    <ListSubheader className=" px-0 leading-normal text-pi-grey text-14 font-medium">FOLLOWERS</ListSubheader>
                  </Stack>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <Typography className=" text-18 font-extrabold">{data && data.reach ? data.reach : 0}</Typography>
                    <ListSubheader className=" px-0 leading-normal text-pi-grey text-14 font-medium">REACH</ListSubheader>
                  </Stack>
                  <Stack justifyContent={'center'} alignItems={'center'}>
                    <Typography className=" text-18 font-extrabold">{data && data.missions ? data.missions : 0}</Typography>
                    <ListSubheader className=" px-0 leading-normal text-pi-grey text-14 font-medium">MISSIONS</ListSubheader>
                  </Stack>
                </Stack>
              </Stack>
            </div>
          </div>

        </StyledAppBar>
        :
        <Box className=" m-auto max-w-max min-w-fit py-16">
          <Typography className=" text-16 self-start">Together, Let's Reach Impossible.</Typography>
          <Typography className=" text-16 self-start">Join the Planet Impossible Network.</Typography>
          <Button component={Link} to="/agentlogin" variant="outlined" color="secondary" className=" mt-16 px-24 py-4 text-pi-blue-1 border-pi-text-grey hover:border-pi-text-grey text-16 rounded-full font-bold self-start"> <Icon className="mr-6 text-pi-blue-1 fill-pi-blue-1">account_circle</Icon> Login</Button>
        </Box>

      }

    </>
  );
}

export default UserNavbarHeader;
