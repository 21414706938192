import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, InfoBox, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import CONFIG from "app/config";
import { Card, TextField, Button, Item, Typography, Container, Dialog, Alert, IconButton, Avatar, MenuItem, Menu, Tooltip, ListItemButton, ListItemIcon, Icon, ListItemText, DialogContent, Divider, DialogTitle, DialogActions, inputLabelClasses, Stack } from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Box, GlobalStyles, height, lighten } from "@mui/system";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import AttachmentUploader from "app/services/AttachmentUploader";
import { deletePublicAttachment, getAllPublicUploads } from "app/services/store/PublicUploadsSlice";
import { updateMissionByType } from "app/main/apps/public/mission-manager-v2/store/missionManagerSlice"
import ReactPlayer from "react-player";
import FuseLoading from "@fuse/core/FuseLoading";
import { useNavigate } from "react-router-dom";

const containerStyle = {
    width: '100%',
    height: '380px'
};

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        // height: 48,
        fontSize: '16px',
        borderRadius: '8px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiInputLabel-root": {
        // height: 48,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '16px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
}));

const DisabledPlayer = styled('div')(({ theme }) => ({
    position: "relative",

    "& .disable": {
        position: "relative",
        height: 80,
        width: 80,
    },
    "& .react-player": {
        position: "absolute",
        top: 0,
        left: 0,
    },


}));

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

const defaultValues = {
    missionId: null,
    title: "",
    kingPin: "",
    description: "",
    lat: "",
    lng: "",
    filesId: null
};

function MissionEditModal(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: CONFIG.MAPS
    })
    const navigate = useNavigate();

    const { item, loadData, popupState } = props;

    const dispatch = useDispatch();
    const token = AgentJwtService.getDecodedAccessToken();
    const [openDialog, setOpenDialog] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({ lat: 40.7128, lng: -74.006 });
    const [map, setMap] = useState(null);

    const [attachmentTempId, setAttachmentTempId] = useState(null);
    const [attachmentData, setAttachmentData] = useState([]);
    const [toDelete, setToDelete] = useState([]);
    const [toUpload, setToUpload] = useState([]);
    const [attachmentLoader, setAttachmentLoader] = useState(true)

    const schema = yup.object().shape({
        title: yup.string().required("Field is required").min(8).max(32),
        description: yup.string().required("Field is required"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const { isValid, dirtyFields, errors } = formState;

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        navigate(0);  
        // var count = 0;
        // for (const id of toUpload) {
        //     count++;
        //     console.log(id);
        //     dispatch(deletePublicAttachment({ id: id })).then(() => {
        //         if (count == toUpload.length) {
        //             setToUpload([]);
        //         }
        //     })
        // }
        // setToDelete([]);
        loadData();
        setOpenDialog(false);
    }

    useEffect(() => {
        if (openDialog) {
            setAttachmentLoader(true)
            if (item) {
                setValue("id", item.id);
                setValue("missionId", item.missionId);
                setValue("title", item.title);
                setValue("description", item.description);
                setValue("kingPin", item.kingPin);
                setValue("filesId", item.filesId);
                setValue("lat", item.lat, { shouldDirty: true });
                setValue("lng", item.lng, { shouldDirty: true });

                const clickedLatLng = {
                    lat: item.lat,
                    lng: item.lng,
                };

                setMarkerPosition(clickedLatLng);
                setAttachmentTempId(item.filesId);

                dispatch(getAllPublicUploads({ id: item.filesId })).then((action) => {
                    const data = action.payload;
                    const finalData = data.map((item, index) => ({
                        ...item,
                        switchType: item.fileType.split('/')[0]
                    }));
                    setAttachmentData(finalData)
                    setAttachmentLoader(false)
                })
            }
        }

    }, [openDialog]);

    const setMissionPin = (e) => {
        const clickedLatLng = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        setValue("lat", e.latLng.lat(), { shouldDirty: true });
        setValue("lng", e.latLng.lng(), { shouldDirty: true });
        setMarkerPosition(clickedLatLng);
    }

    useEffect(() => {
        animateMapCenter(markerPosition);
    }, [markerPosition]);

    const animateMapCenter = (newCenter) => {
        if (map) {
            const start = map.getCenter();
            const startTime = Date.now();
            const duration = 50; // Animation duration in milliseconds

            const animate = () => {
                const now = Date.now();
                const elapsedTime = now - startTime;
                const easing = Math.sin((elapsedTime / duration) * (Math.PI / 2));
                const newLat = start.lat() + easing * (newCenter.lat - start.lat());
                const newLng = start.lng() + easing * (newCenter.lng - start.lng());
                const newCenterPosition = { lat: newLat, lng: newLng };

                map.panTo(newCenterPosition);

                if (elapsedTime < duration) {
                    requestAnimationFrame(animate);
                }
            };
            animate();
        }
    };

    const handleDeleteAttachment = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentData.filter(item => item.id !== id);
            setAttachmentData(updatedAttachments);
        })
        setToDelete([...toDelete, id]);
        // dispatch(deletePublicAttachment({ id: id })).then(() => {
        // const updatedAttachments = attachmentData.filter(item => item.id !== id);
        // setAttachmentData(updatedAttachments);
        // })
    };

    async function handleSave() {
        // Check if the form is valid
        if (!isValid || Object.keys(errors).length > 0) {
            dispatch(
                showMessage({
                    message: "Please fill in all required fields correctly.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
            return;
        }

        const data = getValues();
        data.type = "details";

        try {
            await dispatch(updateMissionByType(data));
            handleCloseDialog();
            loadData();
            popupState.close();
        } catch (error) {
            console.error("Error updating mission:", error);
            dispatch(
                showMessage({
                    message: "An error occurred while updating the mission.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
            
        }
    }

    // async function handleSave() {
    //     var delIsDone = false;
    //     console.log(toDelete);
    //     var count = 0;
    //     for (const id of toDelete) {
    //         count++;
    //         console.log(id);
    //         dispatch(deletePublicAttachment({ id: id })).then(() => {
    //             if (count == toDelete.length) {
    //                 delIsDone = true;
    //             }
    //         })
    //     }
    //     if ((toDelete.length != 0 && delIsDone) || toDelete.length == 0) {
    //         setOpenDialog(false);
    //         const result = await trigger([
    //             "title",
    //             "description",
    //         ]);

    //         if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
    //             return dispatch(
    //                 showMessage({
    //                     message: "Please fill in the required fields.",
    //                     autoHideDuration: 5000, //ms
    //                     anchorOrigin: {
    //                         vertical: "bottom",
    //                         horizontal: "center",
    //                     },
    //                     variant: "error",
    //                 })
    //             );
    //         }

    //         var data = getValues();

    //         data.type = "details";

    //         dispatch(updateMissionByType(data)).then(function () {
    //             handleCloseDialog()
    //             loadData()
    //             popupState.close();
    //         });
    //     }
    // }

    return (
        <>
            <FormProvider {...methods}>
                <Root>
                    <ListItemButton className=" text-pi-blue-1" onClick={handleOpenDialog}>
                        <ListItemIcon sx={{ minWidth: '36px' }}>
                            <Icon className=" text-pi-blue-1">edit</Icon>
                        </ListItemIcon>

                        <ListItemText primary="Edit" />
                    </ListItemButton>

                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="form-dialog-title"
                        scroll="body"
                        sx={{

                            '& .MuiPaper-root': {
                                width: "100%",
                            },
                        }}
                    >
                        <DialogTitle className="flex w-full">
                            <Typography className="font-bold text-18" color="inherit">
                                Edit Mission
                            </Typography>
                        </DialogTitle>
                        <Divider />
                        <DialogContent classes={{ root: 'p-16 pb-0 lg:p-24 lg:pb-0' }}>
                            <div>
                                <Controller
                                    name="title"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldStyled
                                            {...field}
                                            className=" w-full"
                                            error={!!errors.title}
                                            required
                                            helperText={errors?.title?.message}
                                            label="Mission Title"
                                            id="title"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.username ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                        />
                                    )}
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldStyled
                                            {...field}
                                            className="mt-16 w-full"
                                            error={!!errors.description}
                                            required
                                            helperText={errors?.description?.message}
                                            label="Description"
                                            id="description"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.username ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                            multiline
                                            rows={3}
                                        />
                                    )}
                                />
                                <div className="mt-16" style={{ width: "100%", height: "380px" }}>
                                    {/* MAP HERE */}
                                    {isLoaded && (
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={markerPosition}
                                            onLoad={(map) => setMap(map)}
                                            onClick={setMissionPin}
                                            zoom={14}
                                        >
                                            {markerPosition && (
                                                <Marker
                                                    position={markerPosition}
                                                    onClick={setMissionPin}
                                                    draggable={true}
                                                    animation={google.maps.Animation.DROP}
                                                    icon={{
                                                        url: "assets/images/pin-background.png",
                                                    }}
                                                    // onDrag={setMissionPin}
                                                    onDragEnd={setMissionPin}
                                                ></Marker>
                                            )}
                                        </GoogleMap>
                                    )}
                                </div>
                                <AttachmentUploader module="mission" id={attachmentTempId} attachmentData={attachmentData} setAttachmentData={setAttachmentData} toUpload={toUpload} setToUpload={setToUpload} />
                                <Stack direction={'row'} gap={1} className="mt-10" flexWrap={'wrap'}>
                                    {attachmentLoader ? (<FuseLoading />) :
                                        attachmentData.length > 0 &&
                                        attachmentData.map((item) => (
                                            <Stack direction={'row'} gap={1.5} flexWrap={'wrap'}>
                                                <div
                                                    style={{ width: 80, height: 80 }}
                                                    className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                                >

                                                    <Icon className='text-red-600 cursor-pointer absolute top-0 right-5 z-20'
                                                        onClick={() => { handleDeleteAttachment(item.id) }}
                                                    >delete</Icon>
                                                    {
                                                        (() => {
                                                            switch (item.switchType) {
                                                                case "youtube":
                                                                    return <div className="image-container">
                                                                        <DisabledPlayer>
                                                                            <div className=" z-10 disable"></div>
                                                                            <ReactPlayer
                                                                                light={true}
                                                                                playing={false}
                                                                                className="react-player rounded-lg overflow-hidden "
                                                                                url={item.srcUrl}
                                                                                height={80}
                                                                                width={80}
                                                                            />
                                                                        </DisabledPlayer>
                                                                    </div>;
                                                                case "video":
                                                                    return <div className="image-container">
                                                                        <video autoPlay={false} className="blog">
                                                                            <source
                                                                                type={item.fileType}
                                                                                src={item.srcUrl}
                                                                            />
                                                                        </video>
                                                                    </div>;
                                                                default:
                                                                    return <div className="image-container2">
                                                                        <img

                                                                            className="max-w-none w-full h-full object-cover"
                                                                            src={item.srcUrl}
                                                                        />
                                                                    </div>;
                                                            }
                                                        })()
                                                    }
                                                </div>
                                            </Stack>
                                        ))
                                    }

                                </Stack>
                            </div>

                        </DialogContent>
                        <DialogActions className="justify-center px-8 py-16">
                            <div className="px-16">
                                <LoadingButton
                                    className="rounded-md mr-5"
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    // disabled={isSaving}
                                    onClick={handleSave}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    className="rounded-md ml-5"
                                    variant="outlined"
                                    type="button"
                                    onClick={handleCloseDialog}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </Root>
            </FormProvider>
        </>
    )
}

export default MissionEditModal