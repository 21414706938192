
import React, { useState, useRef } from "react";

import { Avatar, Box, CardHeader, Stack, Typography } from "@mui/material";
import CustomRoot from "../custom-component/CustomRoot";

class MissionManagerBg extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="344" height="254" viewBox="0 0 344 254">
                <defs>
                    <clipPath id="clip-path">
                        <rect id="Rectangle_351" data-name="Rectangle 351" width="344" height="254" transform="translate(-16592 -8420)" fill="#fff" stroke="#707070" strokeWidth="1" />
                    </clipPath>
                </defs>
                <g id="Mask_Group_60" data-name="Mask Group 60" transform="translate(16592 8420)" clipPath="url(#clip-path)">
                    <g id="Group_1284" data-name="Group 1284" transform="translate(-16591.568 -8419.565)">
                        <path id="Path_968" data-name="Path 968" d="M552.079,367.618H261.671V161.326H552.079Z" transform="translate(-261.671 -161.326)" fill="#fff" />
                        <path id="Path_969" data-name="Path 969" d="M305.569,328.4a2.83,2.83,0,1,0,0,5.659H403.64a2.83,2.83,0,1,0,0-5.659Z" transform="translate(-281.913 -243.675)" fill="#fbb633" />
                        <path id="Path_970" data-name="Path 970" d="M305.563,361.881a2.83,2.83,0,0,0-.012,5.659h98.156a2.83,2.83,0,0,0,0-5.659Z" transform="translate(-281.907 -260.176)" fill="#fbb633" />
                        <path id="Path_971" data-name="Path 971" d="M305.563,394.881a2.83,2.83,0,0,0-.012,5.659h22.088a2.83,2.83,0,0,0,0-5.659Z" transform="translate(-281.907 -276.441)" fill="#fbb633" />
                        <path id="Path_972" data-name="Path 972" d="M552.079,367.618H261.671V161.326H552.079Zm-285.634-4.774H547.3V166.1H266.444Z" transform="translate(-261.671 -161.326)" fill="#e5e5e5" />
                        <path id="Path_973" data-name="Path 973" d="M649.186,478.732a5.09,5.09,0,0,0-.022,10.18h48.6a5.09,5.09,0,1,0,0-10.18Z" transform="translate(-450.155 -317.769)" fill="#e4e4e4" />
                        <path id="Path_974" data-name="Path 974" d="M649.186,357.185a5.09,5.09,0,0,0-.022,10.18h48.6a5.09,5.09,0,1,0,0-10.18Z" transform="translate(-450.155 -257.861)" fill="#e4e4e4" />
                        <path id="Path_975" data-name="Path 975" d="M305.563,483.381a2.83,2.83,0,0,0-.012,5.659h98.156a2.83,2.83,0,0,0,0-5.659Z" transform="translate(-281.907 -320.061)" fill="#e4e4e4" />
                        <path id="Path_976" data-name="Path 976" d="M305.563,516.381a2.83,2.83,0,0,0-.012,5.659h22.088a2.83,2.83,0,0,0,0-5.659Z" transform="translate(-281.907 -336.326)" fill="#e4e4e4" />
                        <path id="Path_977" data-name="Path 977" d="M305.569,449.9a2.83,2.83,0,0,0,0,5.659H403.64a2.83,2.83,0,0,0,0-5.659Z" transform="translate(-281.913 -303.56)" fill="#e4e4e4" />
                        <path id="Path_978" data-name="Path 978" d="M305.569,206.9a2.83,2.83,0,0,0,0,5.659H403.64a2.83,2.83,0,1,0,0-5.659Z" transform="translate(-281.913 -183.79)" fill="#e4e4e4" />
                        <path id="Path_979" data-name="Path 979" d="M305.563,240.381a2.83,2.83,0,1,0-.012,5.659h98.156a2.83,2.83,0,1,0,0-5.659Z" transform="translate(-281.907 -200.291)" fill="#e4e4e4" />
                        <path id="Path_980" data-name="Path 980" d="M305.563,273.381a2.83,2.83,0,1,0-.012,5.659h22.088a2.83,2.83,0,1,0,0-5.659Z" transform="translate(-281.907 -216.556)" fill="#e4e4e4" />
                        <path id="Path_981" data-name="Path 981" d="M649.186,235.732a5.09,5.09,0,0,0-.022,10.18h48.6a5.09,5.09,0,1,0,0-10.18Z" transform="translate(-450.155 -197.999)" fill="#e4e4e4" />
                        <circle id="Ellipse_130" data-name="Ellipse 130" cx="9.128" cy="9.128" r="9.128" transform="translate(200.141 33.646)" fill="#cacaca" />
                        <path id="Path_982" data-name="Path 982" d="M705.747,367.274a5.06,5.06,0,0,0,5.211,5.748l4.847,10.5,7.1-1.39L715.85,367.4a5.087,5.087,0,0,0-10.1-.127Z" transform="translate(-480.524 -260.653)" fill="#ffb6b6" />
                        <path id="Path_983" data-name="Path 983" d="M498.686,256.055l-19.778-9.783-13.956-23.686L455.2,227.3l12.66,27.363,31.981,18.462Z" transform="translate(-224.358 -109.708)" fill="#3f3d56" />
                        <path id="Path_984" data-name="Path 984" d="M443.989,547.273h-6.217l-2.958-17.926h9.176Z" transform="translate(-214.311 -260.905)" fill="#ffb6b6" />
                        <path id="Path_985" data-name="Path 985" d="M698.22,730.075H678.173v-.254a7.8,7.8,0,0,1,7.8-7.8H698.22Z" transform="translate(-466.957 -437.681)" fill="#2f2e41" />
                        <path id="Path_986" data-name="Path 986" d="M556.579,530.054l-4.338,4.454-14.905-10.388,6.4-6.573Z" transform="translate(-264.843 -255.088)" fill="#ffb6b6" />
                        <path id="Path_987" data-name="Path 987" d="M831.585,704.124,817.6,718.485l-.182-.177a7.8,7.8,0,0,1-.146-11.034h0l8.543-8.771Z" transform="translate(-534.424 -426.09)" fill="#2f2e41" />
                        <path id="Path_988" data-name="Path 988" d="M696.2,515.652h-4.3a120.142,120.142,0,0,0-25.063,42.279L661.7,572.972,680.011,611.3l11.424.2-13.057-40.7,13.155-17.445.663,35.068,42.341,22.193,8.474-12.518-34.454-18.84,5.867-29.829a21.5,21.5,0,0,0,4.931-25.234Z" transform="translate(-458.839 -335.967)" fill="#2f2e41" />
                        <path id="Path_989" data-name="Path 989" d="M782.149,431.068l-17.869-7.826-19.667,8.715s-5.569,3.168-2.019,11.282L721.255,470.1s.039.011,26.857,8.555l24.2-21.307a29.79,29.79,0,0,0,9.842-26.285Z" transform="translate(-488.191 -290.419)" fill="#3f3d56" />
                        <circle id="Ellipse_131" data-name="Ellipse 131" cx="12.455" cy="12.455" r="12.455" transform="translate(278.504 107.681)" fill="#ffb6b6" />
                        <path id="Path_990" data-name="Path 990" d="M839.186,363.65c-2.859-2.326-7.292-2.833-10.243-.626a13.168,13.168,0,0,0-15.792,3.2c-1.328,1.616-2.268,3.8-1.611,5.789s3.49,3.153,4.967,1.672l-.026.61a9.886,9.886,0,0,1-6.147,16.836l12.245,4.946a14.343,14.343,0,0,0,5.341,1.389,4.882,4.882,0,0,0,4.545-2.616l1.55,4.752a78.211,78.211,0,0,0,7.462-15.384,29.331,29.331,0,0,0,2.1-10.785A13.169,13.169,0,0,0,839.186,363.65Z" transform="translate(-532.096 -260.043)" fill="#2f2e41" />
                        <circle id="Ellipse_132" data-name="Ellipse 132" cx="9.128" cy="9.128" r="9.128" transform="translate(200.141 156.876)" fill="#cacaca" />
                        <circle id="Ellipse_133" data-name="Ellipse 133" cx="9.128" cy="9.128" r="9.128" transform="translate(228.539 95.285)" fill="#2eb7d7" />
                        <path id="Path_991" data-name="Path 991" d="M730.747,357.274a5.06,5.06,0,0,0,5.211,5.748l4.847,10.5,7.1-1.39L740.85,357.4a5.087,5.087,0,0,0-10.1-.127Z" transform="translate(-492.846 -255.724)" fill="#ffb6b6" />
                        <path id="Path_992" data-name="Path 992" d="M523.687,246.055l-19.778-9.783-13.956-23.686L480.2,217.3l12.66,27.363,31.981,18.462Z" transform="translate(-236.68 -104.78)" fill="#3f3d56" />
                        <path id="Path_993" data-name="Path 993" d="M749.049,737.688H555.836a.507.507,0,1,1,0-1.014H749.049a.507.507,0,1,1,0,1.014Z" transform="translate(-406.409 -444.904)" fill="#cacaca" />
                    </g>
                </g>
            </svg>
        )
    }
}
function PublicSettings(props) {
    return (
        <CustomRoot>
            <Stack direction={'row'} className=" bg-pi-blue-2 items-end justify-around w-full" style={{ height: "268px" }}>
                <div className="mb-32">
                    <Typography variant="h4" className=" font-extrabold">Settings</Typography>
                    <Typography variant="h5">It's all about Settings page</Typography>
                </div>
                <div>
                    <MissionManagerBg />
                </div>
            </Stack>
        </CustomRoot>
    );
}

export default PublicSettings;
