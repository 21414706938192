import { yupResolver } from "@hookform/resolvers/yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import jwtService from "app/services/jwtService";
import reducer from "./store";
import withReducer from "app/store/withReducer";
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect } from "@fuse/hooks";
import { getDecodedToken } from "./store/lockSlice";
import { Link } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { submitLockScreen } from "app/auth/store/loginSlice";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { hideMessage, showMessage } from "app/store/fuse/messageSlice";
import FormLabel from "@mui/material/FormLabel";
import AppContext from 'app/AppContext';
import { useContext } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    password: yup
        .string()
        .required("Please enter your password.")
        .min(8, "Password is too short - should be 8 chars minimum."),
});

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const defaultValues = {
    password: '',
};

function TimeoutModal({ showModal, togglePopup, handleStayLoggedIn, username }) {
    const dispatch = useDispatch();
    const lock = useSelector(({ lockScreenApp }) => lockScreenApp.lock);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(schema),
    });
    const { control, formState, handleSubmit, reset } = methods;

    useDeepCompareEffect(() => {
        function updateLockState() {
            dispatch(getDecodedToken(jwtService.getAccessToken())).then(
                (action) => { }
            );
        }

        updateLockState();
    }, [dispatch, jwtService.getAccessToken()]);

    const { isValid, dirtyFields, errors } = formState;

    const appContext = useContext(AppContext);
    const { routes } = appContext;
    const location = useLocation();

    const matchedRoutes = matchRoutes(routes, location.pathname);
    const matched = matchedRoutes ? matchedRoutes[0] : false;

    const GET_TOKEN = gql`
    mutation getToken($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        token
        version
        payload
        refreshToken
        refreshExpiresIn
      }
    }
  `;

    const [mutateFunction, { data, loading, error }] = useMutation(GET_TOKEN);

    function onSubmit(model) {
        const { password } = model;

        mutateFunction({
            variables: { username: username, password: password },
            isNoToken: true
        })
            .then(function (data) {
                dispatch(submitLockScreen(data.data.tokenAuth));
                handleStayLoggedIn();

                reset(defaultValues);
            })
            .catch((error) => {
                dispatch(
                    showMessage({
                        message: error.message,
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "warning",
                    })
                );
            });
    }

    function onLogin() {
        jwtService.setSession(null);

        history.push({
            pathname: "/cmslogin",
        });
    }

    if (matched.route.auth && (!matched.route.auth.length || !matched.route.auth || matched.route.auth.includes('agent'))) {
        return (
            <></>
        );
    }

    return (
        <StyledModal open={showModal} BackdropComponent={Backdrop}>
            <motion.div
                initial={{ opacity: 0, scale: 0.6 }}
                animate={{ opacity: 1, scale: 1 }}
            >
                <Card className="w-full max-w-sm">
                    <CardContent className="flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
                        <div className="min-w-full flex flex-col items-center justify-center sm:flex-row sm:justify-start sm:items-center -mx-8">
                            <div className="relative mx-8">
                                <Avatar
                                    className="w-72 h-72"
                                    alt="user photo"
                                    src={
                                        lock && lock.photoURL && lock.photoURL !== ""
                                            ? lock.photoURL
                                            : "assets/images/avatars/profile.jpg"
                                    }
                                />
                                <Icon
                                    className="text-32 absolute right-0 bottom-0"
                                    color="error"
                                >
                                    lock
                                </Icon>
                            </div>

                            <div className="mx-8 flex flex-col items-center sm:items-start">
                                <Typography variant="h6" className="mb-4 font-semibold">
                                    Your session is locked
                                </Typography>
                                <Typography color="textSecondary" className="font-medium">
                                    Due to inactivity, your session is locked. Enter your password
                                    to continue.
                                </Typography>
                            </div>
                        </div>

                        <form
                            name="lockForm"
                            noValidate
                            className="flex flex-col justify-center w-full mt-32"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            {/* <FormLabel id="" className="text-15 font-medium">
                Username <span className="text-red-500">*</span>
              </FormLabel>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-16"
                    //  label="Username"
                    value={username}
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                )}
              /> */}
                            <FormLabel id="" className="text-15 font-medium">
                                Password <span className="text-red-500">*</span>
                            </FormLabel>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-16"
                                        // label="Password"
                                        type="password"
                                        error={!!errors.password}
                                        helperText={errors?.password?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                )}
                            />

                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                color="primary"
                                className="w-224 mx-auto mt-16 rounded-md"
                                aria-label="Reset"
                                disabled={_.isEmpty(dirtyFields) || !isValid}
                                type="submit"
                            >
                                Unlock
                            </LoadingButton>
                        </form>

                        <div className="flex flex-col items-center justify-center pt-32 pb-24">
                            <Link to="#" onClick={onLogin}>
                                Are you not {lock && username} ?
                            </Link>
                        </div>
                    </CardContent>
                </Card>
            </motion.div>
        </StyledModal>
    );
}

export default withReducer("lockScreenApp", reducer)(TimeoutModal);
