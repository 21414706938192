import { authAgentRoles } from 'app/agent';
import PublicBlog from "app/main/apps/public/blog/PublicBlog";
import PublicCommunity from "app/main/apps/public/community/PublicCommunity";
import PublicCommunityDetails from "app/main/apps/public/community/PublicCommunityDetails";
import PublicPrivacypolicy from "app/main/apps/public/privacy-policy/PublicPrivacypolicy";
import PublicTermsofService from "app/main/apps/public/terms-of-service/PublicTermsofService";
import PublicSettings from "app/main/apps/public/settings/PublicSettings";
import PublicDetails from "app/main/apps/public/blog/PublicBlogDetails";
import PublicAbout from "app/main/apps/public/about/PublicAbout";
import PublicHome from "app/main/apps/public/home/PublicHome";
import FileUpload from "app/main/apps/public/shared/file-upload-content/fileUploadWithContent";
import ManageSearch from "./search/ManageSearch";
import PublicMissionDetails from 'app/main/apps/public/mission/PublicMissionDetails';

const PublicConfig = {
    settings: {
        type: "public",
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default",
        },
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authAgentRoles.onlyGuest,
    routes: [
        {
            path: "/",
            element: <PublicHome />,
        },
        {
            path: "public/home",
            element: <PublicHome />,
        },
        {
            path: "public/about",
            element: <PublicAbout />,
        },
        {
            path: "public/blog",
            element: <PublicBlog />,
        },
        {
            path: "public/community",
            element: <PublicCommunity />,
        },
        {
            path: "public/community-details/:id",
            element: <PublicCommunityDetails />,
        },
        {
            path: "public/privacy-policy",
            element: <PublicPrivacypolicy />,
        },
        {
            path: "public/terms-of-service",
            element: <PublicTermsofService />,
        },
        {
            path: "public/settings",
            element: <PublicSettings />,
        },
        {
            path: "public/blog-details",
            element: <PublicDetails />,
        },
        {
            path: "public/result",
            element: <ManageSearch />,
        },
        {
			path: "public/mission-details/:id",
			element: <PublicMissionDetails />,
		},
    ],
};

export default PublicConfig;
