import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import { GlobalStyles, height, minHeight } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon, IconButton, Popover, Skeleton, TextareaAutosize } from "@mui/material";
import { cloneElement, memo } from 'react';
import PublicSidebarLeft from "app/main/apps/public/public-sidebar-left/PublicSidebarLeft";
import { useDispatch, useSelector } from "react-redux";
import PublicSidebarLeftLogo from "app/main/apps/public/public-sidebar-left/PublicSidebarLeftLogo";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/agent/store';
import { Background, Parallax } from "react-parallax";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import { agentById, getMarkersByAgentId } from '../store/agentSlice';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import FriendsActivityJoinMissionModal from "../../public/friends-activity/modal/FriendsActivityJoinMissionModal";
import FriendsActivityCommentModal from "../../public/friends-activity/modal/FriendsActivityCommentModal";
import { PiMapPinLineBold } from "react-icons/pi";
import AgentFollowUnfollow from "app/services/AgentFollowUnfollow";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FuseLoading from "@fuse/core/FuseLoading";
import { getMarkersByMissionId } from "../../public/mission/store/missionDetailsSlice";
import format from "date-fns/format";
import AttachmentSvs from "app/services/AttachmentSvs";
import SkeletonLoader from "../../public/friends-activity/components/SkeletonLoader";
import AgentSkeletonLoader from "./components/AgentSkeletonLoader";
import PhotoGridView from "app/services/PhotoGridView";
import SharerSvs from "app/services/SharerSvs";
import { position } from "stylis";
import { PinIcon, SeenIcon, CommentIcon, PinnedIcon, PinnedIconBlue } from "../../../custom-icon/MissionCounterIcons"



const token = AgentJwtService.getDecodedAccessToken();

const Root = styled(FusePageSimple)(() => ({
    overscrollBehavior: 'contain',
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .agent-profile-follow": {
        marginTop: '4px'
    },
    "& .custom-textarea": {
        width: '100%',
        resize: 'none',
        borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
        padding: '8px',
        marginTop: '5px',
        outline: "none",
        minHeight: '25px'
    },
    "& .custom-textarea:focus": {
        borderBottom: "1.5px solid black",
        transition: "border - color 0.3s",
    }
}));

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #555; /* Default text color */

  &.Mui-selected {
    color: #1da1f2; /* Active tab text color (blue) */
    border-bottom: 2px solid #1da1f2; /* Blue border for active tab */
  }

  &:hover {
    color: #1da1f2;
  }

  

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  border: none; /* Explicitly remove border */
  background-color: transparent; /* Ensure background is transparent */
  box-shadow: none; /* Ensure no box shadow is applied */
  
`;

const TabsList = styled(TabsListUnstyled)`
  display: flex;
  border: none; /* Explicitly remove border */
  background-color: transparent; /* Ensure background is transparent */
  box-shadow: none; /* Ensure no box shadow is applied */
`;


function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
}));
function AgentProfile(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [missions, setMissions] = useState([]);
    const [value, setValue] = React.useState('1');
    const [tabvalue, setTabValue] = React.useState('1');
    const [loading, setLoading] = useState(true);
    const [missionLoading, setmissionLoading] = useState(true);

    const handleChange = (newValue) => {
        setValue(newValue);
        setTabValue(newValue);
        setmissionLoading(true);

    };

    useEffect(() => {
        setLoading(true);
        setmissionLoading(true)
        if (params.get("agentId")) {
            dispatch(agentById(params.get("agentId"))).then((action) => {
                setData(action.payload);
                setLoading(false);
            });
        } else {
            dispatch(agentById(token.id)).then((action) => {
                setData(action.payload);
                setLoading(false);
            });
        }
    }, [dispatch, params.get("agentId")]);

    useEffect(() => {
        dispatch(getMarkersByAgentId({ id: (params.get("agentId") ? data.id : token.agentId), type: (value == '1' ? 'missions' : 'markers') })).then((action) => {
            console.log("MissionDetailContent", action.payload)
            setMissions(action.payload)
            setmissionLoading(false)
        })
    }, [dispatch, data, value]);


    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Stack key={i} direction={'column'} gap={1.5} className=" w-full">
                    <SkeletonLoader />
                </Stack>
            );
        }
        return skeletonLoaders;
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root
                content={
                    <>
                        {loading ? (
                            <Skeleton variant="rectangular" height={260} />
                        ) : (
                            <Box className="relative">
                                <div
                                    className='relative rounded-none overflow-hidden m-auto !shadow-0 bg-pi-light-grey'
                                    style={{ width: "100%", height: "260px" }}
                                >
                                    {data.length != 0 && (
                                        data.bannerUrl ? (
                                            <img className="max-w-none w-full h-full object-cover" src={data.bannerUrl} alt="Cover Photo" />
                                        ) : (
                                            <img className="max-w-none w-full h-full object-cover" src="assets/images/backgrounds/default-cover.png" alt="Cover Photo" />

                                        )
                                    )}
                                </div>
                            </Box>
                        )}
                        <Box className="w-full max1200px:w-lg md:w-10/12 sm:w-full p-16 mt-16 m-auto mb-32 min-h-screen">

                            {loading ? (
                                <Card className="mx-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                    <AgentSkeletonLoader />
                                </Card >
                            ) : (
                                <>
                                    {data.length !== 0 && token.id !== data.userId ? (
                                        null
                                    ) :
                                        <Stack direction={'row'} gap={.5} justifyContent={'flex-end'} className=" -mt-16 mb-16">
                                            <Button variant="contained" component={Link} to={'/public/mission-manager'} size="small" className="bg-pi-light-grey hover:bg-grey-400 rounded-md" startIcon={<Icon>insert_chart</Icon>}>
                                                Mission Manager
                                            </Button>
                                            <Button variant="contained" component={Link} to={'/agentprofile/edit'} size="small" className="bg-pi-light-grey hover:bg-grey-400 rounded-md" startIcon={<Icon>edit</Icon>}>
                                                Edit Page
                                            </Button>
                                        </Stack>}
                                    <Card className="shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                        <React.Fragment>
                                            <CardContent className="!p-24">
                                                <Stack direction={'row'} gap={2.5} className="relative">
                                                    <Stack>
                                                        <div>
                                                            {data.length != 0 && (
                                                                data.profileUrl ? (
                                                                    <Avatar sx={{ width: 136, height: 136 }} alt="user photo" src={data.profileUrl} className=" bg-pi-light-grey" />
                                                                ) : (
                                                                    <Avatar style={{ width: 136, height: 136, fontSize: '42px', textTransform: 'capitalize' }} {...stringAvatar(data?.name)} />
                                                                )
                                                            )}
                                                        </div>
                                                    </Stack>
                                                    <Stack flex={1}>
                                                        <Stack direction={'row'} alignItems={'center'} gap={.5} >
                                                            <Typography className=" text-24 font-extrabold -mb-5 "> {`${data.firstName || ''} ${data.middleName ? data.middleName + ' ' : ''} ${data.lastName || ''}`}</Typography>
                                                            {data.length !== 0 && token.id !== data.userId ? (
                                                                <AgentFollowUnfollow className=' h-fit ' item={data} module='agent-profile' />
                                                            ) : null}
                                                        </Stack>
                                                        <Typography className=" text-16 font-bold text-pi-grey">@{data.username} <span className=" font-normal text-15">{data.agentFollowersCount} followers</span></Typography>
                                                        <Stack direction={'row'} gap={3} className=" w-full mt-16" justifyContent={'start'}>
                                                            <Box
                                                                className="px-10 py-3 font-bold rounded-md bg-default"
                                                                sx={{
                                                                    width: '25%',
                                                                    border: (theme) => `1px solid rgba(240,240,240)`,
                                                                }}
                                                            >
                                                                <Typography className=" text-12 font-bold text-pi-grey">Total Reach</Typography>
                                                                <Typography className=" text-20 font-extrabold">{data.getReach?.missions + data.getReach?.markers || ''}</Typography>
                                                            </Box>
                                                            <Box
                                                                className="px-10 py-3 font-bold rounded-md bg-default"
                                                                sx={{
                                                                    width: '25%',
                                                                    border: (theme) => `1px solid rgba(240,240,240)`,
                                                                }}
                                                            >
                                                                <Typography className=" text-12 font-bold text-pi-grey">Mission Reach</Typography>
                                                                <Typography className=" text-20 font-extrabold">{data.getReach?.missions}</Typography>
                                                            </Box>
                                                            <Box
                                                                className="px-10 py-3 font-bold rounded-md bg-default"
                                                                sx={{
                                                                    width: '25%',
                                                                    border: (theme) => `1px solid rgba(240,240,240)`,
                                                                }}
                                                            >
                                                                <Typography className=" text-12 font-bold text-pi-grey">Colab Reach</Typography>
                                                                <Typography className=" text-20 font-extrabold">{data.getReach?.markers}</Typography>
                                                            </Box>

                                                        </Stack>
                                                    </Stack>


                                                </Stack>
                                                {data.about && (
                                                    <>
                                                        <Typography className="text-24 font-extrabold mt-8 -mb-4 line-clamp-3">Intro</Typography>
                                                        <Typography className={isExpanded ? "line-clamp-none text-16 text-pi-grey cursor-pointer leading-tight mb-24" : "text-16 text-pi-grey line-clamp-3 cursor-pointer leading-tight mb-24"} onClick={toggleReadMore} gutterBottom>{data.about}</Typography>
                                                    </>
                                                )}

                                                <Divider className=" mb-16 mt-20 -mx-20" />
                                                <Stack>
                                                    <TabsUnstyled value={value} onChange={(e, newValue) => handleChange(newValue)} defaultValue={value}>
                                                        <Box sx={{
                                                            width: '100%',
                                                            border: 'none', // Remove grey border here
                                                            boxShadow: 'none', // Remove any shadow as well
                                                        }} className="-mb-24">
                                                            <TabsList aria-label="lab API tabs example" sx={{ '.Mui-selected': { borderBottom: 'unset !important' } }} >
                                                                <Tab value="1" onClick={() => handleChange('1')} label="Maps" className={value === '1' ? 'font-bold !pb-16' : 'font-normal !pb-16'}>
                                                                    Maps
                                                                    {value === "1" && (
                                                                        <div className="h-5 bg-tab-blue rounded-t-4 mt-10 -mx-5 -mb-16"></div>
                                                                    )}
                                                                </Tab>
                                                                <Tab value="2" onClick={() => handleChange('2')} label="Mission Activity" className={value === '2' ? 'font-bold !pb-16' : 'font-normal !pb-16'}>
                                                                    Mission Activity
                                                                    {value === "2" && (
                                                                        <div className="h-5 bg-tab-blue rounded-t-4 mt-10 -mx-5 -mb-16"></div>
                                                                    )}
                                                                </Tab>
                                                                <Tab value="3" onClick={() => handleChange('3')} label="Community" className={value === '3' ? 'font-bold !pb-16' : 'font-normal !pb-16'}>
                                                                    Community
                                                                    {value === "3" && (
                                                                        <div className="h-5 bg-tab-blue rounded-t-4 mt-10 -mx-5 -mb-16"></div>
                                                                    )}
                                                                </Tab>
                                                            </TabsList>
                                                            <TabPanel value="1"></TabPanel>
                                                            <TabPanel value="2"></TabPanel>
                                                            <TabPanel value="3"></TabPanel>
                                                        </Box>

                                                    </TabsUnstyled >
                                                </Stack>

                                            </CardContent>

                                        </React.Fragment>
                                    </Card >
                                </>
                            )}

                            {tabvalue == 1 && ( //Maps tab
                                <>
                                    {missionLoading ? (
                                        renderSkeletonLoaders(3)
                                    ) : (
                                        <>
                                            {missions.length !== 0 ? (
                                                missions.map((item) => (
                                                    <React.Fragment key={item.id}>
                                                        <Card className="p-16 pb-8 rounded-lg mb-18 mt-24 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={item.id}>
                                                            <CardHeader
                                                                className="p-0"
                                                                sx={{
                                                                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                                    "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                                                }}
                                                                avatar={
                                                                    <Badge
                                                                        overlap="circular"
                                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                        sx={{
                                                                            '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                                                borderRadius: '50%',
                                                                                backgroundColor: 'white',
                                                                                padding: '0px',
                                                                                paddingTop: '6px',
                                                                                bottom: '10px',
                                                                                minWidth: '20px',
                                                                                height: '20px'
                                                                            }
                                                                        }}
                                                                        badgeContent={
                                                                            <span
                                                                            >
                                                                                <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                                            </span>
                                                                        }
                                                                    >
                                                                        <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={item.agent} />

                                                                    </Badge>
                                                                }
                                                                title={
                                                                    <Typography className=" font-extrabold text-18">
                                                                        {item.agent.flName}
                                                                    </Typography>
                                                                }
                                                                subheader={
                                                                    <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                                        {format(new Date(item.dateCreated), 'MM/dd/yyyy hh:mm a')}
                                                                    </Typography>
                                                                }
                                                                action={
                                                                    ""
                                                                }
                                                            />
                                                            <CardContent style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + item.missionId)} className="p-0">
                                                                <Typography
                                                                    component="div"
                                                                    className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                                >
                                                                    {item.title}
                                                                </Typography>
                                                                <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                                                    <Typography
                                                                        className="text-16  transition duration-300 line-clamp-3 mb-3 paragraph contents"
                                                                    >
                                                                        {item.description}
                                                                    </Typography>
                                                                </Box>
                                                                <PhotoGridView fileId={item.filesId} />
                                                            </CardContent>
                                                            <CardActions className="flex-col pt-2 pb-0  px-0 justify-start items-start">
                                                                <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                                                    <Typography className=" gap-1 flex items-center ">
                                                                        <SeenIcon />
                                                                        <span className="mt-1">{item.missionViews ? item.missionViews : '0'} Seen</span>
                                                                    </Typography>
                                                                    <Typography className=" gap-1 flex items-center">
                                                                        <CommentIcon />
                                                                        <span className="mt-1">{item.missionCommentsCount ? item.missionCommentsCount : '0'} Comments</span>
                                                                    </Typography>
                                                                    <Typography className=" gap-1 flex items-center">
                                                                        <PinIcon />
                                                                        <span className="mt-1">{item.missionPinned ? item.missionPinned : '0'} Pinned</span>
                                                                    </Typography>
                                                                    <Box className="flex-1 text-right">
                                                                        <SharerSvs link={`/public/mission-details/${item.missionId}`} title={item.title} description={item.description} />
                                                                    </Box>
                                                                </Stack>

                                                            </CardActions>
                                                        </Card>
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <Card className="p-16 pb-8 rounded-lg mb-18 mt-24 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                    <CardContent className="p-0">
                                                        <Typography
                                                            component="div"
                                                            className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                        >
                                                            There are no created missions available
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </>
                                    )}

                                </>

                            )}

                            {tabvalue == 2 && ( //Mission Activity tab
                                <>
                                    <Stack gap={1.5} className="relative">
                                        <Button variant="contained" size="small" className="bg-pi-light-grey hover:bg-grey-400 rounded-md w-fit mt-10 absolute right-0" startIcon={<Icon>filter_list</Icon>}>
                                            Filter
                                        </Button>
                                        <div className=" h-36"></div>
                                        {missionLoading ? (
                                            renderSkeletonLoaders(3)
                                        ) : (

                                            missions.length !== 0 ? (
                                                missions.map((item) => (
                                                    <Card className="p-16 pb-8 rounded-lg mb-18 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={item.id}>
                                                        <CardHeader
                                                            className="p-0"
                                                            sx={{
                                                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                                "& .MuiAvatar-root": { border: '2.5px solid #2EB7D7' },
                                                            }}
                                                            avatar={
                                                                <Badge
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                    sx={{
                                                                        '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                                            borderRadius: '50%',
                                                                            backgroundColor: 'white',
                                                                            padding: '0px',
                                                                            paddingTop: '6px',
                                                                            bottom: '10px',
                                                                            minWidth: '20px',
                                                                            height: '20px'
                                                                        }
                                                                    }}
                                                                    badgeContent={
                                                                        <span
                                                                        >
                                                                            <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                                                                        </span>
                                                                    }
                                                                >
                                                                    <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={item.agent} />

                                                                </Badge>
                                                            }
                                                            title={
                                                                <Typography className=" font-extrabold text-18">
                                                                    {item.agent.flName}
                                                                </Typography>
                                                            }
                                                            subheader={
                                                                <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                                    {format(new Date(item.dateCreated), 'MM/dd/yyyy hh:mm a')}
                                                                </Typography>
                                                            }
                                                            action={
                                                                ""
                                                            }
                                                        />
                                                        <CardContent style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + item.missionId)} className="p-0">
                                                            <Typography
                                                                component="div"
                                                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                            <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                                                <Typography
                                                                    className="text-16  transition duration-300 line-clamp-3 mb-3 paragraph contents"
                                                                >
                                                                    {item.description}
                                                                </Typography>
                                                            </Box>
                                                            <PhotoGridView fileId={item.filesId} />
                                                        </CardContent>

                                                        <CardActions className="flex-col pt-2 pb-0  px-0 justify-start items-start">
                                                            <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                                                <Typography className=" gap-1 flex items-center ">
                                                                    <SeenIcon />
                                                                    <span className="mt-1">{item.missionViews ? item.missionViews : '0'} Seen</span>
                                                                </Typography>
                                                                <Typography className=" gap-1 flex items-center">
                                                                    <CommentIcon />
                                                                    <span className="mt-1">{item.missionCommentsCount ? item.missionCommentsCount : '0'} Comments</span>
                                                                </Typography>
                                                                <Typography className=" gap-1 flex items-center">
                                                                    <PinIcon />
                                                                    <span className="mt-1">{item.missionPinned ? item.missionPinned : '0'} Pinned</span>
                                                                </Typography>
                                                                <Box className="flex-1 text-right">
                                                                    <SharerSvs link={`/public/mission-details/${item.missionId}`} title={item.title} description={item.description} />
                                                                </Box>
                                                            </Stack>

                                                        </CardActions>
                                                    </Card>
                                                ))) : (
                                                <Card className="p-16 pb-8 rounded-lg shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                    <CardContent className="p-0">
                                                        <Typography
                                                            component="div"
                                                            className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                        >
                                                            There are no joined missions available
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            )

                                        )}
                                    </Stack>

                                </>
                            )}

                            {tabvalue == 3 && (
                                <Stack gap={1.5}>
                                    <Card className="p-16 pb-8 rounded-lg mt-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                                        <Stack gap={1} direction={'row'}>
                                            <>
                                                {
                                                    data.length != 0 && (
                                                        data.profileUrl ? (

                                                            <Avatar alt="user photo" src={data.profileUrl} className=" bg-pi-light-grey"
                                                                style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />
                                                        ) : (
                                                            <Avatar alt={"data?.name"} {...stringAvatar(data?.name)}
                                                                className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />
                                                        )
                                                    )
                                                }
                                            </>
                                            <Stack gap={1} flex={1}>
                                                <TextareaAutosize
                                                    maxRows={4}
                                                    className="custom-textarea text-16 leading-tight"
                                                    placeholder="What’s the latest on your mind?!"
                                                />
                                                <Stack direction={'row'} justifyContent={'space-between'} className=" my-10">
                                                    <p style={{ display: 'flex', alignItems: 'center' }} className=" cursor-pointer text-pi-blue-1 text-16">
                                                        <Icon className=" mr-6">attachments</Icon>
                                                        Attached files
                                                    </p>
                                                    <Button variant="contained" color="primary" size="small" className=" font-bold">Post</Button>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Card>

                                    <Card className="p-16 pb-8 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                                        <CardHeader
                                            className="p-0"
                                            sx={{
                                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                // "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                            }}
                                            avatar={
                                                <>
                                                    {
                                                        data.length != 0 && (
                                                            data.profileUrl ? (

                                                                <Avatar alt="user photo" src={data.profileUrl} className=" bg-pi-light-grey"
                                                                    style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />
                                                            ) : (
                                                                <Avatar alt={"data?.name"} {...stringAvatar(data?.name)}
                                                                    className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />
                                                            )
                                                        )
                                                    }
                                                </>
                                            }
                                            title={
                                                <Typography className=" font-extrabold text-18">
                                                    John Paul Ico
                                                </Typography>
                                            }
                                            subheader={
                                                <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                    @superagent · Posted just now
                                                </Typography>
                                            }
                                            action={
                                                ""
                                            }
                                        />
                                        <CardContent style={{ cursor: 'pointer' }}
                                            //  onClick={() => navigate('/public/mission-details/' + item.missionId)} 
                                            className="p-0">

                                            <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                                <Typography
                                                    className="text-16 transition duration-300 line-clamp-3 my-4 paragraph contents"
                                                >
                                                    {/* {item.description} */}
                                                    Hey everyone!

                                                    As we dive into another week, I wanted to take a moment to connect. We all have so much going on in our lives, and it’s important to share and support each other.

                                                    What’s been on your mind lately?
                                                    Whether it’s something exciting, a challenge you’re facing, or just a thought you can’t shake, we’d love to hear from you!

                                                    Feel free to share your thoughts in the comments below. Let’s create a space where we can encourage one another, brainstorm ideas, or just chat about life!

                                                    Looking forward to hearing from you all!
                                                </Typography>
                                            </Box>
                                            {/* <PhotoGridView fileId={item.filesId} /> */}
                                        </CardContent>

                                        <CardActions className="flex-col pt-2 pb-0  px-0 justify-start items-start">
                                            <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                                <Typography className=" gap-1 flex items-center ">
                                                    <SeenIcon />
                                                    <span className="mt-1">101 Seen</span>
                                                </Typography>
                                                <Typography className=" gap-1 flex items-center">
                                                    <CommentIcon />
                                                    <span className="mt-1">101 Comments</span>
                                                </Typography>
                                                <Typography className=" gap-1 flex items-center">
                                                    <PinIcon />
                                                    <span className="mt-1">101 Pinned</span>
                                                </Typography>
                                                <Box className="flex-1 text-right">
                                                    <SharerSvs />
                                                </Box>
                                            </Stack>
                                        </CardActions>
                                    </Card>
                                </Stack>
                            )}

                        </Box >
                    </>
                }
            />

        </>
    );
}

export default withReducer('agentApp', reducer)(AgentProfile);